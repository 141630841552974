import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Dragndrop from "../dragndrop/Dragndrop";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadCourse } from "../../store/actions/items";
import { storage } from "../../../firebase/firebase-config";
import AddIcon from "@mui/icons-material/Add";
import Preview from "../dragndrop/Preview";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { MdNotificationImportant } from "react-icons/md";
const MySwal = withReactContent(Swal);

const AddCourse = ({ handleAddCourse, open, onClose, refresh }) => {
  const [files, setFiles] = useState([]);
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  useEffect(() => {
    setFiles([]); // Clear the added files array
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      imageURL: "",

      isMembersOnly: false,
      isGoldPlan: false,
      isActive: false,
      orderNumber: 0,
      // recommended: false,

      // files: null,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const SubmitForm = async (values) => {
    MySwal.fire({
      title: "¿Confirmar?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        try {
          const file = files[0];
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `images/courses/${values.name}/${file.name}`
          );
          await imageRef.put(file);

          const imageURL = await imageRef.getDownloadURL();

          values.imageURL =
            imageURL !== "" ? imageURL : '"https://picsum.photos/200/300"';

          if (values.tier === 1) {
            values.isMembersOnly = false;
            values.isGoldPlan = false;
          } else if (values.tier === 2) {
            values.isMembersOnly = true;
            values.isGoldPlan = false;
          } else if (values.tier === 3) {
            values.isMembersOnly = true;
            values.isGoldPlan = true;
          }

          const courseID = await uploadCourse(values);

          if (courseID) {
            handleAddCourse(courseID);
          }
          MySwal.fire("Curso Agregado!", "", "success");
        } catch (error) {
          MySwal.fire("Hubo un error al guardar el item", "", "info");
        }

        refresh();
        resetForm();
        onClose();
      }
    });
  };
  const resetForm = () => {
    formik.resetForm();
    setFiles([]);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [files]
  );
  const removeAll = () => {
    setFiles([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{ justifyContent: "center" }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            mb={2}
          >
            <Typography variant={"p"} sx={{ fontWeight: "800" }}>
              Agregar Curso
            </Typography>
            <AddIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={styles.courseCard}>
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              multiline
              rows={3}
              maxRows={4}
              margin="dense"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              label="Descripción"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <TextField
              fullWidth
              label="Seleccione"
              id="tier"
              name="tier"
              select
              value={formik.values.tier}
              onChange={formik.handleChange}
              helperText="Escoja el nivel"
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                },
                PopperProps: {
                  disablePortal: true,
                  placement: "bottom-start", // Adjust the placement as needed
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdNotificationImportant />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {tiers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="orderNumber"
              name="orderNumber"
              label="Número de Orden"
              type="number"
              value={formik.values.orderNumber}
              onChange={formik.handleChange}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <FormControl
                component="fieldset"
                sx={{
                  flexDirection: "row",
                  marginBottom: "15px",
                  mr: 1,
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="isActive"
                  label="Activo"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Box>

            <Box item md={6} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className=" center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "5px",
          }}
          mt={4}
        >
          <Button
            color="error"
            variant="contained"
            onClick={onClose}
            sx={{ marginRight: 2 }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => SubmitForm(formik.values)}
            sx={{ marginLeft: 2 }}
          >
            Crear
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default AddCourse;
