import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TopNavbar from "./TopNavbar";
import { sidebarStyles } from "./../../assets/styles/sidebarStyles";
import MenuDropdownButton from "./menu/MenuDropdownButton";
import MenuButton from "./menu/MenuButton";
import { menu } from './menu/menu';
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SidebarDrawer({ handleDrawerToggle, sidebarOpen, setOpen }) {
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openD, setOpenD] = useState({});

  function handleClick(id) {
    setOpenD((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  return (
    <>
      <TopNavbar drawerWidth={drawerWidth} handleDrawerOpen={handleDrawerOpen} setOpen={setOpen} open={sidebarOpen} />

      <Drawer
        variant={
          !isMobile ? (sidebarOpen ? "permanent" : "permanent") : sidebarOpen ? "permanent" : "temporary"
        }
        open={sidebarOpen}
        sx={sidebarStyles.drawerStyles}
      >
        <DrawerHeader>
          <div className="mr-auto ml-auto">
            <img
              style={{ width: '32px', height: '32px', marginLeft: 20 }}
              src={require("../../assets/img/logos/logoWhite.png")}
              alt="onecore"
            />
          </div>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: 'white' }} />
            ) : (
              <ChevronLeftIcon sx={{ color: 'white' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ color: 'azure' }} />
        <List>
          {menu.map((item) => {
            return (
              <div key={item.title}>
                {item.submenu != null ? (
                  <MenuDropdownButton open={sidebarOpen} openD={openD} handleClick={handleClick} submenu={item.submenu} item={item} />
                ) : (
                    <MenuButton open={sidebarOpen} item={item} />
                  )}
              </div>
            );
          })}
        </List>
        <Divider sx={{ color: 'azure' }} />
      </Drawer>
    </>
  );
}