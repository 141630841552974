export const coins = [
  { id: 1, name: "1INCH", imgURL: require("../../assets/img/coins/1INCH.png") },
  { id: 2, name: "AAVE", imgURL: require("../../assets/img/coins/AAVE.png") },
  { id: 3, name: "ADA", imgURL: require("../../assets/img/coins/ADA.png") },
  { id: 4, name: "AGIX", imgURL: require("../../assets/img/coins/AGIX.png") },
  { id: 5, name: "AKT", imgURL: require("../../assets/img/coins/AKT.png") },
  { id: 6, name: "ALGO", imgURL: require("../../assets/img/coins/ALGO.png") },
  { id: 7, name: "APE", imgURL: require("../../assets/img/coins/APE.png") },
  { id: 8, name: "APT", imgURL: require("../../assets/img/coins/APT.png") },
  { id: 9, name: "AR", imgURL: require("../../assets/img/coins/AR.png") },
  { id: 10, name: "ARB", imgURL: require("../../assets/img/coins/ARB.png") },
  { id: 11, name: "ASTR", imgURL: require("../../assets/img/coins/ASTR.png") },
  { id: 12, name: "ATOM", imgURL: require("../../assets/img/coins/ATOM.png") },
  { id: 13, name: "AVAX", imgURL: require("../../assets/img/coins/AVAX.png") },
  { id: 14, name: "AXL", imgURL: require("../../assets/img/coins/AXL.png") },
  { id: 15, name: "AXS", imgURL: require("../../assets/img/coins/AXS.png") },
  { id: 16, name: "BAT", imgURL: require("../../assets/img/coins/BAT.png") },
  { id: 17, name: "BCH", imgURL: require("../../assets/img/coins/BCH.png") },
  { id: 18, name: "BEAM", imgURL: require("../../assets/img/coins/BEAM.png") },
  { id: 19, name: "BGB", imgURL: require("../../assets/img/coins/BGB.png") },
  { id: 20, name: "BLUR", imgURL: require("../../assets/img/coins/BLUR.png") },
  { id: 21, name: "BNB", imgURL: require("../../assets/img/coins/BNB.png") },
  { id: 22, name: "BSV", imgURL: require("../../assets/img/coins/BSV.png") },
  { id: 23, name: "BTC", imgURL: require("../../assets/img/coins/BTC.png") },
  { id: 24, name: "BTG", imgURL: require("../../assets/img/coins/BTG.png") },
  { id: 25, name: "BTT", imgURL: require("../../assets/img/coins/BTT.png") },
  { id: 26, name: "BUSD", imgURL: require("../../assets/img/coins/BUSD.png") },
  { id: 27, name: "CAKE", imgURL: require("../../assets/img/coins/CAKE.png") },
  { id: 28, name: "CELO", imgURL: require("../../assets/img/coins/CELO.png") },
  { id: 29, name: "CFX", imgURL: require("../../assets/img/coins/CFX.png") },
  { id: 30, name: "CHZ", imgURL: require("../../assets/img/coins/CHZ.png") },
  { id: 31, name: "COMP", imgURL: require("../../assets/img/coins/COMP.png") },
  { id: 32, name: "CRO", imgURL: require("../../assets/img/coins/CRO.png") },
  { id: 33, name: "CRV", imgURL: require("../../assets/img/coins/CRV.png") },
  { id: 34, name: "CSPR", imgURL: require("../../assets/img/coins/CSPR.png") },
  { id: 35, name: "CVX", imgURL: require("../../assets/img/coins/CVX.png") },
  { id: 36, name: "DAI", imgURL: require("../../assets/img/coins/DAI.png") },
  { id: 37, name: "DASH", imgURL: require("../../assets/img/coins/DASH.png") },
  { id: 38, name: "DOGE", imgURL: require("../../assets/img/coins/DOGE.png") },
  { id: 39, name: "DOT", imgURL: require("../../assets/img/coins/DOT.png") },
  { id: 40, name: "EGLD", imgURL: require("../../assets/img/coins/EGLD.png") },
  { id: 41, name: "ELF", imgURL: require("../../assets/img/coins/ELF.png") },
  { id: 42, name: "ENJ", imgURL: require("../../assets/img/coins/ENJ.png") },
  { id: 43, name: "ENS", imgURL: require("../../assets/img/coins/ENS.png") },
  { id: 44, name: "EOS", imgURL: require("../../assets/img/coins/EOS.png") },
  { id: 45, name: "ETC", imgURL: require("../../assets/img/coins/ETC.png") },
  { id: 46, name: "ETH", imgURL: require("../../assets/img/coins/ETH.png") },
  {
    id: 47,
    name: "ETHDYDX",
    imgURL: require("../../assets/img/coins/ETHDYDX.png"),
  },
  { id: 48, name: "ETHW", imgURL: require("../../assets/img/coins/ETHW.png") },
  {
    id: 49,
    name: "FDUSD",
    imgURL: require("../../assets/img/coins/FDUSD.png"),
  },
  { id: 50, name: "FET", imgURL: require("../../assets/img/coins/FET.png") },
  { id: 51, name: "FIL", imgURL: require("../../assets/img/coins/FIL.png") },
  {
    id: 52,
    name: "FLOKI",
    imgURL: require("../../assets/img/coins/FLOKI.png"),
  },
  { id: 53, name: "FLOW", imgURL: require("../../assets/img/coins/FLOW.png") },
  { id: 54, name: "FLR", imgURL: require("../../assets/img/coins/FLR.png") },
  { id: 55, name: "FTM", imgURL: require("../../assets/img/coins/FTM.png") },
  { id: 56, name: "FTT", imgURL: require("../../assets/img/coins/FTT.png") },

  { id: 57, name: "FXS", imgURL: require("../../assets/img/coins/FXS.png") },
  { id: 58, name: "GALA", imgURL: require("../../assets/img/coins/GALA.png") },
  { id: 59, name: "GAS", imgURL: require("../../assets/img/coins/GAS.png") },
  { id: 60, name: "GMT", imgURL: require("../../assets/img/coins/GMT.png") },
  { id: 61, name: "GMX", imgURL: require("../../assets/img/coins/GMX.png") },
  { id: 62, name: "GNO", imgURL: require("../../assets/img/coins/GNO.png") },
  { id: 63, name: "GRT", imgURL: require("../../assets/img/coins/GRT.png") },
  { id: 64, name: "GT", imgURL: require("../../assets/img/coins/GT.png") },
  { id: 65, name: "HBAR", imgURL: require("../../assets/img/coins/HBAR.png") },
  { id: 66, name: "HNT", imgURL: require("../../assets/img/coins/HNT.png") },
  { id: 67, name: "HOT", imgURL: require("../../assets/img/coins/HOT.png") },
  { id: 68, name: "HT", imgURL: require("../../assets/img/coins/HT.png") },
  { id: 69, name: "ICP", imgURL: require("../../assets/img/coins/ICP.png") },
  { id: 70, name: "ILV", imgURL: require("../../assets/img/coins/ILV.png") },
  { id: 71, name: "IMX", imgURL: require("../../assets/img/coins/IMX.png") },
  { id: 72, name: "INJ", imgURL: require("../../assets/img/coins/INJ.png") },
  { id: 73, name: "IOTA", imgURL: require("../../assets/img/coins/IOTA.png") },
  {
    id: 74,
    name: "JASMY",
    imgURL: require("../../assets/img/coins/JASMY.png"),
  },
  { id: 75, name: "JST", imgURL: require("../../assets/img/coins/JST.png") },
  { id: 76, name: "KAS", imgURL: require("../../assets/img/coins/KAS.png") },
  { id: 77, name: "KAVA", imgURL: require("../../assets/img/coins/KAVA.png") },
  { id: 78, name: "KCS", imgURL: require("../../assets/img/coins/KCS.png") },
  { id: 79, name: "KLAY", imgURL: require("../../assets/img/coins/KLAY.png") },
  { id: 80, name: "LDO", imgURL: require("../../assets/img/coins/LDO.png") },
  { id: 81, name: "LEO", imgURL: require("../../assets/img/coins/LEO.png") },
  { id: 82, name: "LINK", imgURL: require("../../assets/img/coins/LINK.png") },
  { id: 83, name: "LRC", imgURL: require("../../assets/img/coins/LRC.png") },
  { id: 84, name: "LTC", imgURL: require("../../assets/img/coins/LTC.png") },
  { id: 85, name: "LUNA", imgURL: require("../../assets/img/coins/LUNA.png") },
  { id: 86, name: "LUNC", imgURL: require("../../assets/img/coins/LUNC.png") },
  { id: 87, name: "MANA", imgURL: require("../../assets/img/coins/MANA.png") },
  { id: 88, name: "MASK", imgURL: require("../../assets/img/coins/MASK.png") },
  {
    id: 89,
    name: "MATIC",
    imgURL: require("../../assets/img/coins/MATIC.png"),
  },
  { id: 90, name: "MEME", imgURL: require("../../assets/img/coins/MEME.png") },
  { id: 91, name: "MINA", imgURL: require("../../assets/img/coins/MINA.png") },
  { id: 92, name: "MKR", imgURL: require("../../assets/img/coins/MKR.png") },
  { id: 93, name: "MNT", imgURL: require("../../assets/img/coins/MNT.png") },
  { id: 94, name: "MX", imgURL: require("../../assets/img/coins/MX.png") },
  { id: 95, name: "NEAR", imgURL: require("../../assets/img/coins/NEAR.png") },
  { id: 96, name: "NEO", imgURL: require("../../assets/img/coins/NEO.png") },
  { id: 97, name: "NEXO", imgURL: require("../../assets/img/coins/NEXO.png") },
  { id: 98, name: "NFT", imgURL: require("../../assets/img/coins/NFT.png") },
  {
    id: 99,
    name: "OCEAN",
    imgURL: require("../../assets/img/coins/OCEAN.png"),
  },
  { id: 100, name: "OKB", imgURL: require("../../assets/img/coins/OKB.png") },
  { id: 101, name: "OP", imgURL: require("../../assets/img/coins/OP.png") },
  { id: 102, name: "ORDI", imgURL: require("../../assets/img/coins/ORDI.png") },
  { id: 103, name: "OSMO", imgURL: require("../../assets/img/coins/OSMO.png") },
  { id: 104, name: "PAXG", imgURL: require("../../assets/img/coins/PAXG.png") },
  { id: 105, name: "PEPE", imgURL: require("../../assets/img/coins/PEPE.png") },
  { id: 106, name: "QNT", imgURL: require("../../assets/img/coins/QNT.png") },
  { id: 107, name: "QTUM", imgURL: require("../../assets/img/coins/QTUM.png") },
  { id: 108, name: "RNDR", imgURL: require("../../assets/img/coins/RNDR.png") },
  { id: 109, name: "ROSE", imgURL: require("../../assets/img/coins/ROSE.png") },
  { id: 110, name: "RPL", imgURL: require("../../assets/img/coins/RPL.png") },
  { id: 111, name: "RUNE", imgURL: require("../../assets/img/coins/RUNE.png") },
  { id: 112, name: "SAND", imgURL: require("../../assets/img/coins/SAND.png") },
  { id: 113, name: "SC", imgURL: require("../../assets/img/coins/SC.png") },
  { id: 114, name: "SEI", imgURL: require("../../assets/img/coins/SEI.png") },
  { id: 115, name: "SFP", imgURL: require("../../assets/img/coins/SFP.png") },
  { id: 116, name: "SHIB", imgURL: require("../../assets/img/coins/SHIB.png") },
  { id: 117, name: "SNX", imgURL: require("../../assets/img/coins/SNX.png") },
  { id: 118, name: "SOL", imgURL: require("../../assets/img/coins/SOL.png") },
  {
    id: 119,
    name: "STORJ",
    imgURL: require("../../assets/img/coins/STORJ.png"),
  },
  { id: 120, name: "STX", imgURL: require("../../assets/img/coins/STX.png") },
  { id: 121, name: "SUI", imgURL: require("../../assets/img/coins/SUI.png") },
  {
    id: 122,
    name: "SUSHI",
    imgURL: require("../../assets/img/coins/SUSHI.png"),
  },
  {
    id: 123,
    name: "TFUEL",
    imgURL: require("../../assets/img/coins/TFUEL.png"),
  },
  {
    id: 124,
    name: "THETA",
    imgURL: require("../../assets/img/coins/THETA.png"),
  },
  { id: 125, name: "TIA", imgURL: require("../../assets/img/coins/TIA.png") },
  { id: 126, name: "TON", imgURL: require("../../assets/img/coins/TON.png") },
  { id: 127, name: "TRX", imgURL: require("../../assets/img/coins/TRX.png") },
  { id: 128, name: "TUSD", imgURL: require("../../assets/img/coins/TUSD.png") },
  { id: 129, name: "TWT", imgURL: require("../../assets/img/coins/TWT.png") },
  { id: 130, name: "UNI", imgURL: require("../../assets/img/coins/UNI.png") },
  { id: 131, name: "USDC", imgURL: require("../../assets/img/coins/USDC.png") },
  { id: 132, name: "USDD", imgURL: require("../../assets/img/coins/USDD.png") },
  { id: 133, name: "USDP", imgURL: require("../../assets/img/coins/USDP.png") },
  { id: 134, name: "USDT", imgURL: require("../../assets/img/coins/USDT.png") },
  { id: 135, name: "USTC", imgURL: require("../../assets/img/coins/USTC.png") },
  { id: 136, name: "VET", imgURL: require("../../assets/img/coins/VET.png") },
  { id: 137, name: "WLD", imgURL: require("../../assets/img/coins/WLD.png") },
  { id: 138, name: "WOO", imgURL: require("../../assets/img/coins/WOO.png") },
  { id: 139, name: "XAUt", imgURL: require("../../assets/img/coins/XAUt.png") },
  { id: 140, name: "XDC", imgURL: require("../../assets/img/coins/XDC.png") },
  { id: 141, name: "XEC", imgURL: require("../../assets/img/coins/XEC.png") },
  { id: 142, name: "XEM", imgURL: require("../../assets/img/coins/XEM.png") },
  { id: 143, name: "XLM", imgURL: require("../../assets/img/coins/XLM.png") },
  { id: 144, name: "XMR", imgURL: require("../../assets/img/coins/XMR.png") },
  { id: 145, name: "XRP", imgURL: require("../../assets/img/coins/XRP.png") },
  { id: 146, name: "XTZ", imgURL: require("../../assets/img/coins/XTZ.png") },
  { id: 147, name: "YFI", imgURL: require("../../assets/img/coins/YFI.png") },
  { id: 148, name: "ZEC", imgURL: require("../../assets/img/coins/ZEC.png") },
  { id: 149, name: "ZIL", imgURL: require("../../assets/img/coins/ZIL.png") },
  { id: 150, name: "ZRX", imgURL: require("../../assets/img/coins/ZRX.png") },
];
