import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  postPushNotificationActive,
  postPushNotificationAll,
  postPushNotificationForced,
  postPushNotificationGold,
} from "../store/actions/notifications";
import { useSelector } from "react-redux";
import { refreshToken } from "../store/actions/auth";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { MdSignalCellular1Bar } from "react-icons/md";
import { getExpiredStatus } from "../store/actions/items";

const MySwal = withReactContent(Swal);

const AddNotifications = ({ users }) => {
  const { idToken } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(false);
  const [preloadedUsers, setPreloadedUsers] = useState([]);
  const [expired, setexpired] = useState(false);
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  //Formik
  const validationSchema = yup.object({
    title: yup.string("Ingrese el titulo").required("Campo Obligatorio"),
    text: yup
      .string("Ingrese el texto de la notificación")
      .required("Campo Obligatorio"),
    // itemId: yup.string("Ingrese un Id de item").required("Campo Obligatorio"),
  });
  const refreshT = async () => {
    const t = await refreshToken();
    console.log(t);
    return t;
  };

  const preLoadUserList = async (selectedTier) => {
    let filteredUsers;

    switch (selectedTier) {
      case 1:
        filteredUsers = users.filter((user) => user.pushToken !== undefined);
        break;
      case 2:
        filteredUsers = users.filter(
          (user) =>
            user.isActive && !user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      case 3:
        filteredUsers = users.filter(
          (user) => user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      default:
        filteredUsers = [];
    }

    const pTokens = filteredUsers.map((user) => user.pushToken).filter(Boolean);

    setPreloadedUsers(pTokens);
  };

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);
  const formik = useFormik({
    initialValues: {
      title: "",
      text: "",
      isActive: false,
      isGoldPlan: false,
      level: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const SubmitForm = (values) => {
    let loadingTimer;
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error de facturación",
        text: "Contacte a Soporte",
      });
      return;
    }
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere enviar esta notificación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      const selectedTier = values.level;
      const data = {
        title: values.title ?? "",
        text: values.text ?? "",
        type: "",
        audience:
          selectedTier === 1
            ? "basic"
            : selectedTier === 2
            ? "intermediate"
            : "advanced",
        pushTokens: preloadedUsers,
      };

      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: `Enviando a  usuarios de ${
            values.level === 1
              ? "Nivel Básico"
              : values.level === 2
              ? "Nivel Intermedio"
              : "Nivel Avanzado"
          }`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            // Set a timer to close the Swal loading after 3.5 seconds
            loadingTimer = setTimeout(() => {
              MySwal.close();
              MySwal.fire("Notificación Enviada!", "", "success");
            }, 3500);
          },
          showConfirmButton: false,
        });

        try {
          const tokenToUse = isValidToken(idToken)
            ? idToken
            : await refreshToken();
          // const apiCallStartTime = performance.now();

          await postPushNotificationAll(data, tokenToUse);

          // const apiCallEndTime = performance.now();
          // const apiCallDuration = apiCallEndTime - apiCallStartTime;
          // console.log(`API Call Duration: ${apiCallDuration} milliseconds`);

          // Clear the timer and close the Swal loading
          clearTimeout(loadingTimer);
          MySwal.close();
        } catch (error) {
          console.error("Error:", error);
          MySwal.fire("Hubo un error al enviar la notificación", "", "error");
        }
      } else if (result.isDenied) {
        // Handle other cases if needed
      }
    });
  };
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  return (
    <Box sx={styles.cardStyle} mb={4}>
      <form onSubmit={formik.handleSubmit} className="m-auto p-2">
        <Typography sx={styles.cardTitleStyle} variant="h6" className="center">
          Agregar Notificación
        </Typography>
        <Box sx={styles.innerCardStyle}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              id="level"
              name="level"
              select
              label="Nivel"
              value={formik.values.level}
              onChange={formik.handleChange}
              error={formik.touched.level && Boolean(formik.errors.level)}
              SelectProps={{
                getContentAnchorEl: null,
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  disableScrollLock: true,
                },
                // PopperProps: {
                //   disablePortal: true,
                //   placement: "bottom-start", // Adjust the placement as needed
                // },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSignalCellular1Bar />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {tiers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
              flexDirection: "column",
            }}
          >
            <Button
              className="w-100"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ mb: 2 }}
              onClick={() => preLoadUserList(formik.values.level)}
            >
              Precargar lista
            </Button>
          </Box>
          {preloadedUsers.length > 0 && (
            <Box>
              {/* <Typography sx={{ fontSize: "1rem !important" }}>
                {formik.values.level === 1
                  ? "Nivel Básico"
                  : formik.values.level === 2
                  ? "Nivel Intermedio"
                  : "Nivel Avanzado"}{" "}
              </Typography> */}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: "1rem !important",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  Audiencia: {"  "}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "700 !important",
                    fontSize: "1rem !important",
                    ml: 0.51,
                  }}
                >
                  {preloadedUsers.length}
                </Typography>
              </Box>
            </Box>
          )}
          <Box className="">
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="title"
              name="title"
              label="Nombre"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="text"
              name="text"
              label="Descripción"
              type="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
            {/* <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="itemId"
              name="itemId"
              label="Id de Item"
              type="text"
              value={formik.values.itemId}
              onChange={formik.handleChange}
              error={formik.touched.itemId && Boolean(formik.errors.itemId)}
              helperText={formik.touched.itemId && formik.errors.itemId}
            /> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
            }}
          >
            <Button
              className="w-50"
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={preloadedUsers.length === 0}
            >
              Crear
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddNotifications;
