import React, { useCallback, useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styles } from "../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddCourse from "./wizard/AddCourse";
import AddVideo from "./wizard/AddVideo";
import EditCourse from "./wizard/EditCourse";
import { useTheme } from "@mui/material";

const MySwal = withReactContent(Swal);

const AddAcademy = ({ courses, refresh }) => {
  const theme = useTheme();
  const [step, setStep] = useState(1);
  const [courseId, setCourseId] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenCourse = (courseId) => {
    setCourseId(courseId);
    handleNextStep();
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleAddCourse = (courseId) => {
    setCourseId(courseId);
    refresh();
    handleNextStep();
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                marginTop: "15px",

                marginBottom: "40px",
                width: "300px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => setOpenModal(true)}
              >
                Agregar Curso
              </Button>
            </Box>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "flex-start" }}
              alignItems={"center"}
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {courses.map((course) => (
                <React.Fragment key={course.id}>
                  <EditCourse
                    course={course}
                    handleOpenCourse={handleOpenCourse}
                  />
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        );
      case 2:
        return <AddVideo courseId={courseId} />;
      default:
        return null;
    }
  };

  return (
    <Box mb={4} style={styles.wizardCard}>
      <AddCourse
        handleAddCourse={handleAddCourse}
        open={openModal}
        onClose={() => setOpenModal(false)}
        refresh={refresh}
      />
      <Box sx={{ position: "relative", height: "100%" }}>
        {renderStepContent()}
      </Box>
      {step === 2 && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem",
          }}
        >
          <Button
            disabled={step === 1}
            onClick={handlePrevStep}
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            Volver
          </Button>
          {/* <Button onClick={handleNextStep}>
            {step === 1 ? "Next" : "Finish"}
          </Button> */}
        </Box>
      )}
    </Box>
  );
};

export default AddAcademy;
