import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { storage } from "../../../firebase/firebase-config";
import { MdHome } from "react-icons/md";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTheme } from "@mui/material";
import { editLiveEvent } from "../../store/actions/items";
import { Close } from "@mui/icons-material";

const MySwal = withReactContent(Swal);

const LiveEventsEditModal = ({ onClose, open, item, refresh }) => {
  const theme = useTheme();
  const [value, setValue] = useState("live");
  const [refreshing, setRefreshing] = useState(false);
  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");

  useEffect(() => {
    if (item.imageURL) {
      setOgImage(item.imageURL);
    }
  }, [item]);
  console.log(item);
  const formik = useFormik({
    initialValues: {
      name: item.name ?? "",
      imageURL: item.imageURL ?? "",
      link: item.link ?? "",
    },
    enableReinitialize: true,
    validationSchema: () => {
      if (value === "live") {
        return yup.object({
          name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
        });
      }
      return yup.object({});
    },
    onSubmit: (values) => {
      SubmitForm(values);

      console.log({
        files: values.files.map((file) => ({
          fileName: file.name,
          type: file.type,
          size: `${file.size} bytes`,
        })),
      });
    },
  });
  const handleRemoveImg = () => {
    formik.setFieldValue("imageURL", "");
    setOgImage(""); // Set the imageURL field to an empty value
  };
  const SubmitForm = async (values) => {
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere editar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            MySwal.showLoading();
          },
        });

        if (images.length > 0) {
          const file = images[0];
          const storageRef = storage.ref();

          // Upload the new image inside the course folder
          const imageRef = storageRef.child(
            `images/items/liveEvent/${file.name}`
          );
          await imageRef.put(file);
          values.imageURL = await imageRef.getDownloadURL();
        } else {
          // If no new images are selected, keep the original imageURL
          values.imageURL = ogImage || "https://picsum.photos/200/300";
        }

        const liveData = {
          name: values.name,
          imageURL: values.imageURL,
          id: item.id,
          link: values.link,
        };

        try {
          await editLiveEvent(liveData);
          refresh();

          loadingSwal.close();

          MySwal.fire({
            title: "Guardado Exitosamente",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          onClose();
        } catch (error) {
          // Hide the loading indicator
          loadingSwal.close();

          // Show error message
          MySwal.fire({
            title: "Error al guardar",
            text: "Ha ocurrido un error al guardar los cambios.",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          });
          onClose();
        }
      }
    });
  };
  //Dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);
    URL.revokeObjectURL(images[0].preview);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Evento
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12} className="mb-4">
            <Box sx={[styles.cardStyle, { width: 500 }]}>
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="link"
                    name="link"
                    label="Enlace"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    error={formik.touched.link && Boolean(formik.errors.link)}
                    helperText={formik.touched.link && formik.errors.link}
                  />

                  {ogImage === "" ? (
                    <Box item md={6} sx={styles.uploadBoxStyle}>
                      {images.length === 0 ? (
                        <div className=" center m-auto">
                          <Dragndrop
                            onDrop={onDrop}
                            accept={"image/*"}
                            maxFiles={1}
                          />
                        </div>
                      ) : (
                        <Preview files={images} removeAll={removeAll} />
                      )}
                    </Box>
                  ) : (
                    <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={[
                            styles.deleteIcon,
                            { background: theme.palette.error.main },
                          ]}
                          onClick={() => handleRemoveImg()}
                        >
                          <HighlightOffIcon />
                        </Box>
                        <img
                          src={item.imageURL}
                          alt="Original"
                          style={{
                            width: "auto",
                            maxHeight: "125px",
                            borderRadius: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "row",
                      marginBottom: "5px",
                    }}
                    mt={4}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LiveEventsEditModal;
