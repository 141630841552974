import Swal from "sweetalert2";
import { db } from "../../../firebase/firebase-config";
import { types } from "../types/types";
import { ADMIN_API_URL } from "../../variables/globals";
export const SET_ITEMS = "SET_ITEMS";
export const SET_COURSES = "SET_COURSES";
export const SET_IS_LOADING = "SET_IS_LOADING";

export const getAllItems = () => {
  return (dispatch) => {
    const items = [];
    dispatch(isLoading(true));
    db.collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(getItems(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllLiveEvents = () => {
  return (dispatch) => {
    const items = [];
    dispatch(isLoading(true));
    db.collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(getItems(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllCourses = () => {
  return async (dispatch) => {
    const items = [];

    await db
      .collection("courses")
      .orderBy("orderNumber", "asc")
      .get()
      .then(async (itemsFromFs) => {
        // console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(setCourses(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getExpiredStatus = (updateStatusCallback) => {
  const unsubscribe = db
    .collection("businesses")
    .doc("root")
    .onSnapshot((snapshot) => {
      // Accessing the "paymentExpired" field from the retrieved document
      const statusExpired = snapshot.data().paymentExpired;
      updateStatusCallback(statusExpired);
    });

  // Returning the unsubscribe function to allow cleanup
  return unsubscribe;
};

export const getCourse = async (courseId) => {
  try {
    const courseRef = db.collection("courses").doc(courseId);
    const courseSnapshot = await courseRef.get();

    if (courseSnapshot.exists) {
      const courseData = courseSnapshot.data();

      // Fetch the videos subcollection
      const videosSnapshot = await courseRef.collection("videos").get();
      const videos = videosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return { courseData, videos };
    } else {
      throw new Error("Course not found");
    }
  } catch (error) {
    console.error("Error fetching course data:", error);
    throw error;
  }
};

export const uploadVideo = async (courseId, video, videoId = null) => {
  try {
    let videoRef;

    if (videoId) {
      videoRef = await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .doc(videoId)
        .update({
          name: video.name,
          videoURL: video.videoURL,
          category: video.category,
          duration: video.duration,
          tags: video.tags.split(",").map((tag) => tag.trim()),
        });
    } else {
      videoRef = await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .add({
          name: video.name,
          videoURL: video.videoURL,
          category: video.category,
          cdate: new Date(),
          duration: video.duration,
          isGoldPlan: false,
          isMembersOnly: false,
          tags: video.tags.split(",").map((tag) => tag.trim()),
          usersCount: 0,
        });
    }

    console.log("Video uploaded/updated successfully!");
    return videoRef.id; // Return the video ID
  } catch (error) {
    console.error("Error uploading/updating video:", error);
    return null;
  }
};
export const editVideo = async (courseId, video, videoId) => {
  try {
    const videoRef = await db
      .collection("courses")
      .doc(courseId)
      .collection("videos")
      .doc(videoId)
      .update({
        name: video.name,
        videoURL: video.videoURL,
        category: video.category,
        duration: video.duration,
        tags: Array.isArray(video.tags)
          ? video.tags
          : video.tags.split(",").map((tag) => tag.trim()),

        usersCount: video.usersCount,
        orderNumber: video.orderNumber,
      });

    console.log("Video uploaded/updated successfully!");
    return videoRef; // Return the video ID
  } catch (error) {
    console.error("Error uploading/updating video:", error);
    return null;
  }
};

// SCHEDULES

export const uploadScheduleEvent = async (values, scheduleName) => {
  try {
    // Find the schedule document with name: "main"
    const scheduleRef = await db
      .collection("schedules")
      .where("name", "==", scheduleName)
      .get();

    if (!scheduleRef.empty) {
      // Get the ID of the first matching document
      const scheduleId = scheduleRef.docs[0].id;

      // Add the values to a new document within the "events" subcollection
      await db
        .collection("schedules")
        .doc(scheduleId)
        .collection("events")
        .add(values);

      console.log("Event uploaded successfully");
    } else {
      console.log("No schedule document found with name: 'main'");
    }
  } catch (error) {
    console.error("Error uploading event", error);
    return null;
  }
};

export const editScheduleEvent = async (eventId, values, scheduleName) => {
  try {
    const scheduleRef = await db
      .collection("schedules")
      .where("name", "==", scheduleName)
      .get();

    if (!scheduleRef.empty) {
      const scheduleId = scheduleRef.docs[0].id;

      await db
        .collection("schedules")
        .doc(scheduleId)
        .collection("events")
        .doc(eventId)
        .set(values, { merge: true });

      console.log("Event updated successfully");
    } else {
      console.log("No schedule document found with name: 'main'");
    }
  } catch (error) {
    console.error("Error updating event", error);
    return null;
  }
};

// export const uploadCourse = async (courseData) => {
//   try {
//     const courseRef = await db.collection("courses").add({
//       name: courseData.name,
//       description: courseData.description,
//       imageURL: courseData.imageURL,
//       cdate: new Date(),
//     });

//     const courseId = courseRef.id;

//     for (const video of courseData.videos) {
//       await db
//         .collection("courses")
//         .doc(courseId)
//         .collection("videos")
//         .add({
//           name: video.name,
//           videoURL: video.videoURL,
//           category: video.category,
//           cdate: new Date(),
//           courseDuration: video.courseDuration,
//           isGoldPlan: video.isGoldPlan,
//           isMembersOnly: video.isMembersOnly,
//           tags: video.tags.split(",").map((tag) => tag.trim()), // Convert comma-separated tags to an array
//           usersCount: video.usersCount,
//         });
//     }

//     console.log("Course uploaded successfully!");
//   } catch (error) {
//     console.error("Error uploading course:", error);
//   }
// };

export const uploadCourse = async (courseData) => {
  try {
    const courseRef = await db.collection("courses").add({
      name: courseData.name,
      description: courseData.description,
      imageURL: courseData.imageURL,
      cdate: new Date(),
      isGoldPlan: courseData.isGoldPlan,
      isMembersOnly: courseData.isMembersOnly,
      isActive: true,
      orderNumber: courseData.orderNumber,
    });

    console.log("Course uploaded successfully!");
    return courseRef.id; // Return the course ID
  } catch (error) {
    console.error("Error uploading course:", error);
    return null;
  }
};
export const editCourse = async (courseData) => {
  try {
    await db.collection("courses").doc(courseData.id).set(
      {
        name: courseData.name,
        author: courseData.author,
        description: courseData.description,
        imageURL: courseData.imageURL,
        isGoldPlan: courseData.isGoldPlan,
        isMembersOnly: courseData.isMembersOnly,
        isActive: courseData.isActive,
        orderNumber: courseData.orderNumber,
      },
      { merge: true }
    );

    console.log("Course updated successfully!");
  } catch (error) {
    console.error("Error updating course:", error);
    return null;
  }
};

export const uploadCourseVideos = async (values, courseId) => {
  try {
    for (const video of values.videos) {
      await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .add({
          name: video.name,
          videoURL: video.videoURL,
          category: video.category,
          cdate: new Date(),
          duration: video.courseDuration,
          isGoldPlan: video.isGoldPlan,
          isMembersOnly: video.isMembersOnly,
          tags: video.tags.split(",").map((tag) => tag.trim()),
          usersCount: video.usersCount,
        });
    }

    console.log("Videos uploaded successfully!");
  } catch (error) {
    console.error("Error uploading videos:", error);
  }
};

export const uploadFlyer = async (values, tag) => {
  try {
    await db.collection("items").add({
      name: values.name,
      description: values.description,
      imageURL: values.imageURL,
      category: "events",
      details: values.details,
      streamURL: values.streamURL,
      cdate: new Date(),
      tags: ["home"],
      isActive: true,
    });

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const editFlyer = async (values, tag) => {
  try {
    await db
      .collection("items")
      .doc(values.id)
      .set(
        {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "events",
          details: values.details,
          streamURL: values.streamURL,
          cdate: new Date(),
          tags: ["home"],
        },
        { merge: true }
      );

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const editLiveEvent = async (values, tag) => {
  try {
    await db
      .collection("items")
      .doc(values.id)
      .set(
        {
          link: values.link,
          name: values.name,
          imageURL: values.imageURL,
          cdate: new Date(),
          tags: ["liveEvent"],
        },
        { merge: true }
      );

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const editLiveEventStatus = async (id, status, tag) => {
  console.log(id, status);
  try {
    await db.collection("items").doc(id).set(
      {
        isActive: status,
      },
      { merge: true }
    );

    console.log("status uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const uploadLiveEvent = async (values, tag) => {
  try {
    await db.collection("items").add({
      name: values.name,
      imageURL: values.imageURL,
      cdate: new Date(),
      tags: ["liveEvent"],
      isActive: true,
    });

    console.log("Live Event uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};

export const deleteFirebaseItem = async (itemId) => {
  try {
    await db.collection("courses").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteFirebaseNotification = async (itemId) => {
  try {
    await db.collection("notifications").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const setCourseActivation = async (itemId, state) => {
  console.log(itemId, state);
  try {
    await db.collection("courses").doc(itemId).set(
      {
        isActive: state,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};
export const deleteFirebaseUser = async (itemId) => {
  try {
    await db.collection("courses").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteItem = async (itemId) => {
  try {
    await db.collection("items").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteVideo = async (courseId, itemId) => {
  try {
    await db
      .collection("courses")
      .doc(courseId)
      .collection("videos")
      .doc(itemId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const callDeleteAlert = (
  elementsToDelete,
  deleteItem,
  handleRefresh,
  successMsg
  //deleteElement
) => {
  Swal.fire({
    title: "Alerta",
    text: "¿Esta seguro que desea eliminar?",
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteItem(elementsToDelete);
      //deleteElement()
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};
export const callDeleteAlertCourse = (
  elementsToDelete,
  deleteFirebaseItem,
  handleRefresh,
  successMsg
  //deleteElement
) => {
  Swal.fire({
    title: "Alerta",
    text: "¿Esta seguro que desea eliminar?",
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFirebaseItem(elementsToDelete);
      console.log(elementsToDelete);
      //deleteElement()
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};

// SIGNALS

export const changeCategory = (category) => {
  return (dispatch) => {
    dispatch(changeCat(category));
  };
};

export const changeSearch = (searchValue) => {
  return (dispatch) => {
    dispatch(searchChange(searchValue));
  };
};

export const getItems = (items) => ({
  type: SET_ITEMS,
  payload: {
    items,
  },
});

export const setCourses = (courses) => ({
  type: SET_COURSES,
  payload: {
    courses,
  },
});

export const changeCat = (category) => ({
  type: types.changeCategory,
  payload: {
    category,
  },
});

export const searchChange = (searchValue) => ({
  type: types.searchValue,
  payload: {
    searchValue,
  },
});

export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
