import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { storage } from "../../../firebase/firebase-config";
import { MdHome } from "react-icons/md";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import { editFlyer } from "../../store/actions/items";
import { useTheme } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close } from "@mui/icons-material";

const MySwal = withReactContent(Swal);

const homeItems = [
  {
    value: "home",
    label: "Flyer Carrusel",
    fields: [
      "category",
      "cdate",
      "description",
      "details",
      "imageURL",
      "name",
      "streamURL",
      "tags",
    ],
  },
  {
    value: "live",
    label: "Banner Evento en Vivo",
    fields: ["cdate", "imageURL", "name", "tags"],
  },
];
const ItemsEditModal = ({ onClose, open, item, refresh }) => {
  const theme = useTheme();
  const [value, setValue] = useState("live");
  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (item.imageURL) {
      setOgImage(item.imageURL);
    }
    console.log(item);
  }, [item, images]);

  useEffect(() => {
    // Clear added items when the component is loaded
    setImages([]); // Clear the added files array
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    images.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const formik = useFormik({
    initialValues: {
      contentType: "home",
      name: item.name ?? "",
      category: item.category ?? "",
      details: item.details ?? "",
      imageURL: item.imageURL ?? "",
      streamURL: item.streamURL ?? "",
      description: item.description ?? "",
      files: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
      description: yup
        .string("Ingrese una descripción")
        .required("Campo Obligatorio"),
    }),

    onSubmit: (values) => {
      SubmitForm(values);

      console.log({
        files: values.files.map((file) => ({
          fileName: file.name,
          type: file.type,
          size: `${file.size} bytes`,
        })),
      });
    },
  });
  const handleRemoveImg = () => {
    formik.setFieldValue("imageURL", "");
    setOgImage(""); // Set the imageURL field to an empty value
  };
  const SubmitForm = async (values) => {
    // Show loading indicator
    MySwal.fire({
      title: "Guardando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      // Display confirmation dialog
      const result = await MySwal.fire({
        title: "¿Confirmar?",
        text: "¿Está seguro que quiere editar este item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Agregar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        MySwal.fire("Guardado!", "", "success");

        if (images.length > 0) {
          const file = images[0];
          const storageRef = storage.ref();

          // Upload the new image inside the course folder
          const imageRef = storageRef.child(`images/items/home/${file.name}`);
          await imageRef.put(file);
          values.imageURL = await imageRef.getDownloadURL();
        } else {
          // If no new images are selected, keep the original imageURL
          values.imageURL = ogImage || "https://picsum.photos/200/300";
        }

        const homeData = {
          id: item.id,
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "events",
          details: values.details,
          imageURL: values.imageURL,
          streamURL: values.streamURL,
        };

        await editFlyer(homeData);
        refresh();
        onClose();
      } else if (result.isDenied) {
        MySwal.fire("Cambios no guardados", "", "info");
      }
    } catch (error) {
      // Show error message
      MySwal.fire({
        title: "Error al guardar",
        text: "Ha ocurrido un error al guardar los cambios.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  //Dropzone

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);
    URL.revokeObjectURL(images[0].preview);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Banner Carrusel
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12} className="mb-4">
            <Box sx={[styles.cardStyle, { width: 500 }]}>
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="description"
                    name="description"
                    label="Descripción"
                    type="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="details"
                    name="details"
                    label="Detalles"
                    type="details"
                    value={formik.values.details}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.details && Boolean(formik.errors.details)
                    }
                    helperText={formik.touched.details && formik.errors.details}
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="streamURL"
                    name="streamURL"
                    label="URL de transmisión"
                    value={formik.values.streamURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.streamURL &&
                      Boolean(formik.errors.streamURL)
                    }
                    helperText={
                      formik.touched.streamURL && formik.errors.streamURL
                    }
                  />

                  {ogImage === "" ? (
                    <Box item md={6} sx={styles.uploadBoxStyle}>
                      {images.length === 0 ? (
                        <div className=" center m-auto">
                          <Dragndrop
                            onDrop={onDrop}
                            accept={"image/*"}
                            maxFiles={1}
                          />
                        </div>
                      ) : (
                        <Preview files={images} removeAll={removeAll} />
                      )}
                    </Box>
                  ) : (
                    <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                      <Box
                        sx={{ justifyContent: "center", alignItems: "center" }}
                      >
                        <Box
                          sx={[
                            styles.deleteIcon,
                            { background: theme.palette.error.main },
                          ]}
                          onClick={() => handleRemoveImg()}
                        >
                          <HighlightOffIcon />
                        </Box>
                        <img
                          src={item.imageURL}
                          alt="Original"
                          style={{
                            width: "auto",
                            maxHeight: "125px",
                            borderRadius: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ItemsEditModal;
