import React, { useState } from "react";
import {
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { MdClose } from "react-icons/md";

export default function CustomSearch({
  searchType,
  setSearchType,
  searchValue,
  setSearchValue,
  handleSearch,
  hideSearch,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <TextField
        type="text"
        placeholder="Buscar..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: searchValue !== "" && (
            <IconButton onClick={hideSearch}>
              <MdClose />
            </IconButton>
          ),
          sx: {
            minHeight: 50,
          },
        }}
        sx={{ mr: 3 }}
      />

      <Select
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        sx={{ mr: 3, pb: 0, maxHeight: "50px" }}
      >
        <MenuItem value="email">Correo Electrónico</MenuItem>
        <MenuItem value="name">Nombre</MenuItem>
        <MenuItem value="brokerId">Broker ID</MenuItem>
        <MenuItem value="discordId">Discord ID</MenuItem>
      </Select>
      <Button
        onClick={handleSearch}
        variant="contained"
        color="primary"
        disabled={searchValue === ""}
        sx={{ height: "50px", minWidth: "150px" }}
      >
        Buscar
      </Button>
    </Box>
  );
}
