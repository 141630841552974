import jwtDecode from "jwt-decode";
import "sweetalert2/dist/sweetalert2.min.css";
import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  checkIfUserExists,
  createAdmin,
  createUser,
} from "../store/actions/users";

import { useSelector } from "react-redux";
import { refreshToken } from "../store/actions/auth";

import { MdNotificationImportant } from "react-icons/md";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";

const MySwal = withReactContent(Swal);

const AddUsers = ({ refresh, isAdmin }) => {
  const { idToken } = useSelector((state) => state.auth);
  const validationSchema = yup.object({
    name: yup.string().required("Campo Obligatorio"),
    email: yup
      .string()
      .email("Ingrese un correo electrónico válido")
      .required("Campo Obligatorio"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      lastname2: "",
      email: "",
      password: "",
      phone: "",
      discordId: "",
      brokerId: "",
      tier: 1,
      isAdmin: false,
      adminClaim: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  const admins = [
    {
      value: "admin",
      label: "admin",
    },
    {
      value: "superadmin",
      label: "superadmin",
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const resetForm = () => {
    formik.resetForm();
  };
  const SubmitForm = async (values) => {
    console.log({
      name: values.name,
      lastname: values.lastname,
      lastname2: values.lastname2,
      email: values.email,

      phone: values.phone,
      discordId: values.discordId,
      brokerId: values.brokerId,
      tier: values.tier,
      adminClaim: values.adminClaim,
    });
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });

        const userData = {
          name: values.name,
          lastname: values.lastname,
          lastname2: values.lastname2,
          email: values.email,
          phone: values.phone,
          discordId: values.discordId,
          brokerId: values.brokerId,
          tier: values.tier,
        };
        const adminData = {
          name: values.name,
          lastname: values.lastname,
          lastname2: values.lastname2,
          email: values.email,
          phone: values.phone,
          discordId: values.discordId,
          brokerId: values.brokerId,
          tier: values.tier,
          adminClaim: values.adminClaim,
        };

        if (values.isAdmin) {
          try {
            if (isValidToken(idToken)) {
              const res = await createAdmin(adminData, idToken);
              if (res.ok) {
                MySwal.fire(
                  `Administrador ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email}  `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            } else {
              const newToken = await refreshToken();

              const res = await createAdmin(adminData, newToken);
              if (res.ok) {
                MySwal.fire(
                  `Administrador ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email} `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            }
          } catch (error) {
            console.error("Error creating user:", error);

            MySwal.fire("Hubo un error al guardar el usuario", "", "error");
          }
        } else {
          const userCheckResult = await checkIfUserExists(values.brokerId);
          if (userCheckResult.exists && !userCheckResult.isDisabled) {
            MySwal.fire(
              "Ya existe un usuario con el mismo Broker Id",
              "",
              "error"
            );
          } else {
            try {
              if (isValidToken(idToken)) {
                const res = await createUser(userData, idToken);
                if (res.ok) {
                  MySwal.fire(
                    `Usuario ${
                      values.name + " " + values.lastname
                    } creado con el correo ${values.email}  `,
                    "",
                    "success"
                  );
                } else if (res.error.code === 11) {
                  MySwal.fire("El correo ya existe", "", "error");
                }
              } else {
                const newToken = await refreshToken();
                const res = await createUser(userData, newToken);
                if (res.ok) {
                  MySwal.fire(
                    `Usuario ${
                      values.name + " " + values.lastname
                    } creado con el correo ${values.email} `,
                    "",
                    "success"
                  );
                } else if (res.error.code === 11) {
                  MySwal.fire("El correo ya existe", "", "error");
                }
              }
            } catch (error) {
              console.error("Error creating user:", error);

              MySwal.fire("Hubo un error al guardar el usuario", "", "error");
            }
          }
        }
        refresh();
        resetForm();
      } else if (result.dismiss === "cancel") {
        MySwal.fire("Cambios no guardados", "", "info");
        refresh();
      }
    });
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item lg={6} sm={8} xs={12} sx={styles.cardStyle} className=" mb-4 ">
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Usuario
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Correo Electrónico"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              type="text"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="lastname"
              name="lastname"
              type="text"
              label="Apellido"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="lastname2"
              name="lastname2"
              type="text"
              label="Segundo Apellido"
              value={formik.values.lastname2}
              onChange={formik.handleChange}
              error={
                formik.touched.lastname2 && Boolean(formik.errors.lastname2)
              }
              helperText={formik.touched.lastname2 && formik.errors.lastname2}
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="phone"
              name="phone"
              type="phone"
              label="Telêfono"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="discordId"
              name="discordId"
              type="discordId"
              label="Discord ID"
              value={formik.values.discordId}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.discordId)}
              helperText={formik.touched.phone && formik.errors.discordId}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="brokerId"
              name="brokerId"
              type="brokerId"
              label="Broker ID"
              value={formik.values.brokerId}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.brokerId)}
              helperText={formik.touched.phone && formik.errors.brokerId}
            />
            <TextField
              fullWidth
              label="Seleccione"
              id="tier"
              name="tier"
              onClick={(e) => e.preventDefault()}
              select
              value={formik.values.tier}
              onChange={formik.handleChange}
              autoFocus={false}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,

                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdNotificationImportant />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {tiers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControl
              component="fieldset"
              sx={{
                marginLeft: "0px !important",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                value="isAdmin"
                label="Usuario Administrador"
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isAdmin"
                    checked={formik.values.isAdmin}
                    onChange={formik.handleChange}
                    disabled={isAdmin}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </FormControl>

            <TextField
              fullWidth
              label="Seleccione"
              onClick={(e) => e.preventDefault()}
              id="adminClaim"
              name="adminClaim"
              disabled={!formik.values.isAdmin}
              select
              value={formik.values.adminClaim}
              onChange={formik.handleChange}
              helperText="Escoja la sección que desea cambiar"
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,

                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdNotificationImportant />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {admins.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* {formik.values.isAdmin && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="password"
                name="password"
                type="password"
                label="Contraseña"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            )} */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!formik.isValid}
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddUsers;
