import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { storage } from "../../../firebase/firebase-config";
import { MdNotificationImportant } from "react-icons/md";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import { editCourse } from "../../store/actions/items";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close } from "@mui/icons-material";

const MySwal = withReactContent(Swal);

const AcademyEditModal = ({ onClose, open, course, refresh }) => {
  const theme = useTheme();

  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (course.imageURL) {
      setOgImage(course.imageURL);
    }
  }, [course]);

  useEffect(() => {
    setFiles([]);
  }, []);
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];

  const defaultTierValue =
    course.isMembersOnly && !course.isGoldPlan
      ? 2
      : course.isGoldPlan && course.isMembersOnly
      ? 3
      : 1;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: course.name || "",
      author: course.author || "",
      description: course.description || "",
      imageURL: course.imageURL || "",
      isMembersOnly: course.isMembersOnly,
      isGoldPlan: course.isGoldPlan,
      isActive: course.isActive || false,
      tier: defaultTierValue,
      orderNumber: course.orderNumber || 0,
    },
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const handleRemoveImg = () => {
    formik.setFieldValue("imageURL", "");
    setOgImage(""); // Set the imageURL field to an empty value
  };

  const SubmitForm = async (values) => {
    const isActive = values.tier === 2 || values.tier === 3;
    const isGoldPlan = values.tier === 3;
    // Show confirmation dialog
    const result = await MySwal.fire({
      title: "¿Confirmar?",
      text: "¿Está seguro que quiere editar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Editar",
      cancelButtonText: "Cancelar",
    });

    // If the user confirmed, proceed with the edit
    if (result.isConfirmed) {
      // Show loading indicator
      const loadingSwal = MySwal.fire({
        title: "Guardando...",
        html: '<div class="swal2-spinner"></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          MySwal.showLoading();
        },
      });

      // Check if there are new images selected
      if (images.length > 0) {
        const file = images[0];
        const storageRef = storage.ref();

        // Upload the new image inside the course folder
        const imageRef = storageRef.child(
          `images/courses/${course.name}/${file.name}`
        );
        await imageRef.put(file);
        values.imageURL = await imageRef.getDownloadURL();
      } else {
        // If no new images are selected, keep the original imageURL
        values.imageURL = ogImage || "https://picsum.photos/200/300";
      }

      if (values.tier === 1) {
        values.isMembersOnly = false;
        values.isGoldPlan = false;
      } else if (values.tier === 2) {
        values.isMembersOnly = true;
        values.isGoldPlan = false;
      } else if (values.tier === 3) {
        values.isMembersOnly = true;
        values.isGoldPlan = true;
      }

      const data = {
        author: values.author,
        name: values.name,
        description: values.description,
        imageURL: values.imageURL,
        id: course.id,
        isGoldPlan: values.isGoldPlan,
        isMembersOnly: values.isMembersOnly,
        isActive: values.isActive,
        orderNumber: values.orderNumber || 0,
      };

      try {
        await editCourse(data);

        refresh();
        // Hide the loading indicator
        loadingSwal.close();

        // Show success message
        MySwal.fire("Cambios guardados", "", "success");

        onClose();
      } catch (error) {
        // Hide the loading indicator
        loadingSwal.close();

        // Show error message
        MySwal.fire("Error al guardar los cambios", "", "error");

        refresh();
        onClose();
      }
    }
  };

  //Dropzone

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setFiles([]);
  };
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1251 }}
      maxWidth="xs"
      PaperProps={{
        style: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Curso
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12}>
            <Box>
              <Box sx={styles.innerCardStyle}>
                <form onSubmit={formik.handleSubmit}>
                  <Box sx={styles.courseCard}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        mb={1}
                        variant={"p"}
                        sx={{ fontWeight: "800" }}
                      >
                        {course.name}
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="name"
                        name="name"
                        label="Nombre"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        multiline
                        rows={3}
                        maxRows={4}
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="description"
                        name="description"
                        label="Descripción"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="author"
                        name="author"
                        label="Autor"
                        value={formik.values.author}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.author && Boolean(formik.errors.author)
                        }
                        helperText={
                          formik.touched.author && formik.errors.author
                        }
                      />
                    </Box>
                    <TextField
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="orderNumber"
                      name="orderNumber"
                      label="Número de Orden"
                      type="number"
                      value={formik.values.orderNumber}
                      onChange={formik.handleChange}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <FormControl
                        component="fieldset"
                        sx={{
                          flexDirection: "row",
                          marginBottom: "15px",
                          mr: 1,
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            marginLeft: "0px !important",
                            flexDirection: "row",
                          }}
                          value="isActive"
                          label="Activo"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              name="isActive"
                              checked={formik.values.isActive}
                              onChange={formik.handleChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </FormControl>
                    </Box>
                    {ogImage === "" ? (
                      <Box item md={6} sx={styles.uploadBoxStyle}>
                        {images.length === 0 ? (
                          <div className=" center m-auto">
                            <Dragndrop
                              onDrop={onDrop}
                              accept={"image/*"}
                              maxFiles={1}
                            />
                          </div>
                        ) : (
                          <Preview files={images} removeAll={removeAll} />
                        )}
                      </Box>
                    ) : (
                      <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                        <Box
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={[
                              styles.deleteIcon,
                              { background: theme.palette.error.main },
                            ]}
                            onClick={() => handleRemoveImg()}
                          >
                            <HighlightOffIcon />
                          </Box>
                          <img
                            src={course.imageURL}
                            alt="Original"
                            style={{
                              width: "auto",
                              maxHeight: "125px",
                              borderRadius: "18px",
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "row",
                      marginBottom: "5px",
                    }}
                    mt={4}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AcademyEditModal;
