import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdSignalCellular1Bar, MdNotificationImportant } from "react-icons/md";
import { addSignal, putSignal } from "../store/actions/signals";
import Dragndrop from "./dragndrop/Dragndrop";

import { storage } from "../../firebase/firebase-config";
import { refreshToken } from "../store/actions/auth";
import { useSelector } from "react-redux";
import Preview from "./dragndrop/Preview";
import { coins } from "../utils/coinsList";
import {
  postPushNotificationActive,
  postPushNotificationAll,
  postPushNotificationGold,
} from "../store/actions/notifications";
import { getExpiredStatus } from "../store/actions/items";

const MySwal = withReactContent(Swal);

const signalType = [
  {
    value: "forex",
    label: "Forex",
  },
];
const signalMode = [
  {
    value: "--",
    label: "--",
  },
  {
    value: "BUY",
    label: "Buy",
  },
  {
    value: "SELL",
    label: "Sell",
  },
];
const categories = [
  {
    value: "market",
    label: "Orden a Mercado",
  },
  {
    value: "pending",
    label: "Orden Pendiente",
  },
];
const status = [
  {
    value: "active",
    label: "Activa",
  },
  { value: "pending", label: "Pendiente" },
  {
    value: "won",
    label: "Ganada",
  },
  {
    value: "lost",
    label: "Perdida",
  },
  {
    value: "break",
    label: "Break Even",
  },
  {
    value: "cancelled",
    label: "Cancelada",
  },
  {
    value: "closed",
    label: "Cerrada",
  },
  {
    value: "custom",
    label: "Personalizada",
  },
];

const tiers = [
  {
    value: 1,
    label: "Nivel Básico",
  },
  {
    value: 2,
    label: "Nivel Intermedio",
  },
  {
    value: 3,
    label: "Nivel Avanzado",
  },
];
const AddSignals = ({ refresh, users }) => {
  const { idToken } = useSelector((state) => state.auth);
  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);
  const [preloadedUsers, setPreloadedUsers] = useState([]);
  const [expired, setexpired] = useState(false);
  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]);
    setImages([]); // Clear the added files array
  }, []);

  const validationSchema = yup.object({
    type: yup.string("Ingrese un tipo de señal").required("Campo Obligatorio"),
    currency: yup.string("Ingrese una moneda").required("Campo Obligatorio"),
    price: yup.string("Ingrese un precio").required("Campo Obligatorio"),
    eta: yup.string("Ingrese un tiempo estimado").required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      signalType: "forex",
      alias: "",
      type: "",
      currency: "",
      entryPrice: "",
      eta: "",
      signalMode: "",
      stopLoss: "",
      tp: "",
      tp1: "",
      status: "",
      category: "",
      picture: "",
      imageDescription: "",
      tier: 1,
      isMembersOnly: false,
      isGoldPlan: false,
      isActive: true,
      motive: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const preLoadUserList = async (selectedTier) => {
    let filteredUsers;

    switch (selectedTier) {
      case 1:
        filteredUsers = users.filter((user) => user.pushToken !== undefined);
        break;
      case 2:
        filteredUsers = users.filter(
          (user) =>
            user.isActive && !user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      case 3:
        filteredUsers = users.filter(
          (user) => user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      default:
        filteredUsers = [];
    }

    const pTokens = filteredUsers.map((user) => user.pushToken).filter(Boolean);

    setPreloadedUsers(pTokens);
  };
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);
  const SubmitForm = async (values) => {
    let loadingTimer;

    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Esta seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      const timestamp = Date.now();
      console.log(values);
      const file = images[0];
      const storageRef = storage.ref();
      const fileNameWithDate = `${file?.name}_${timestamp}`;
      // Upload the new image inside the course folder
      const imageRef = storageRef.child(
        `images/items/signals/${fileNameWithDate}`
      );
      await imageRef.put(file);
      values.picture = await imageRef.getDownloadURL();
      if (values.tier === 1) {
        values.isMembersOnly = false;
        values.isGoldPlan = false;
      } else if (values.tier === 2) {
        values.isMembersOnly = true;
        values.isGoldPlan = false;
      } else if (values.tier === 3) {
        values.isMembersOnly = true;
        values.isGoldPlan = true;
      }

      const signalData = {
        alias: values.alias,
        type: values.type,
        currency: values.currency,
        category: values.category,
        entryPrice: values.entryPrice,
        eta: values.eta,
        stopLoss: values.stopLoss,
        tp: values.tp,
        tp1: values.tp, // New field
        status: values.status === "active" ? "" : values.status,
        cdate: new Date(),
        picture: values.picture,
        imageDescription: values.imageDescription,
        isGoldPlan: values.isGoldPlan,
        isMembersOnly: values.isMembersOnly,
        isActive: values.isActive ?? true,
        motive: values.motive ?? "",
      };

      const getStatusSignal = () => {
        switch (values.status) {
          case "active":
            switch (values.type) {
              case "BUY":
                return "¡COMPRAR YA!";
              case "SELL":
                return "¡VENDER YA!";
              case "--":
                return "ACTIVA";
              default:
            }
            break;
          case "pending":
            return values.motive !== "" ? values.motive : "PENDIENTE";
          case "won":
            return values.motive !== "" ? values.motive : "GANADA";
          case "lost":
            return values.motive !== "" ? values.motive : "PERDIDA";
          case "break":
            return "BREAK EVEN";
          case "cancelled":
            return values.motive !== "" ? values.motive : "CANCELADA";
          case "closed":
            return values.motive !== "" ? values.motive : "CERRADA";
          case "custom":
            return values.motive ?? "";
          default:
            return "PENDIENTE";
        }
      };

      const selectedTier = values.tier;
      const data = {
        title: `¡Nueva Señal! ${values.currency}`,
        text: getStatusSignal(),

        type: "signal",
        audience:
          selectedTier === 1
            ? "basic"
            : selectedTier === 2
            ? "intermediate"
            : "advanced",
        pushTokens: preloadedUsers,
      };

      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: `Enviando a  usuarios de ${
            values.level === 1
              ? "Nivel Básico"
              : values.level === 2
              ? "Nivel Intermedio"
              : "Nivel Avanzado"
          }`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            // Set a timer to close the Swal loading after 3.5 seconds
            loadingTimer = setTimeout(() => {
              MySwal.close();
              MySwal.fire("Notificación Enviada!", "", "success");
            }, 4500);
          },
          showConfirmButton: false,
        });
        try {
          await addSignal(signalData);

          const tokenToUse = isValidToken(idToken)
            ? idToken
            : await refreshToken();
          // const apiCallStartTime = performance.now();

          await postPushNotificationAll(data, tokenToUse);

          // const apiCallEndTime = performance.now();
          // const apiCallDuration = apiCallEndTime - apiCallStartTime;
          // console.log(`API Call Duration: ${apiCallDuration} milliseconds`);

          clearTimeout(loadingTimer);
          MySwal.close();

          refresh();
          resetForm();
        } catch (error) {
          console.error(error);
          MySwal.close();
          MySwal.fire({
            title: "Error",
            text: "Ocurrió un error al enviar la señal. Por favor, inténtelo de nuevo más tarde.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };
  const isHistory =
    formik.values.status !== "active" &&
    formik.values.status !== "break" &&
    formik.values.status !== "pending";
  //Dropzone
  const resetForm = () => {
    formik.resetForm();
    setFiles([]);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);

    URL.revokeObjectURL(images[0].preview);
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item md={8} xl={6} lg={6} sm={10} mb={6} xs={12}>
        <Box sx={styles.cardStyle}>
          <form onSubmit={formik.handleSubmit} className="m-auto p-2">
            <Typography
              sx={styles.cardTitleStyle}
              variant="h6"
              className="center"
            >
              Agregar Señales
            </Typography>
            <Box sx={styles.innerCardStyle}>
              <Box mt={1} sx={{ width: "95%" }}>
                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={12}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        marginTop: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <Button
                        className="w-50"
                        color="primary"
                        variant="contained"
                        fullWidth
                        sx={{ mb: 2 }}
                        onClick={() => preLoadUserList(formik.values.tier)}
                      >
                        Precargar lista
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={12} container justifyContent={"center"}>
                    {preloadedUsers.length > 0 && (
                      <Box>
                        {/* <Typography sx={{ fontSize: "1rem !important" }}>
                {formik.values.level === 1
                  ? "Nivel Básico"
                  : formik.values.level === 2
                  ? "Nivel Intermedio"
                  : "Nivel Avanzado"}{" "}
              </Typography> */}
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              fontSize: "1rem !important",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            Audiencia: {"  "}
                          </Typography>

                          <Typography
                            sx={{
                              fontWeight: "700 !important",
                              fontSize: "1rem !important",
                              ml: 0.51,
                            }}
                          >
                            {preloadedUsers.length}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      fullWidth
                      id="type"
                      name="type"
                      select
                      label="Acción"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdSignalCellular1Bar />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    >
                      {signalMode.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      fullWidth
                      id="category"
                      name="category"
                      select
                      label="Categoría"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdSignalCellular1Bar />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="status"
                      name="status"
                      select
                      label="Status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start",
                        },
                      }}
                    >
                      {status.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="motive"
                      name="motive"
                      label="Motivo"
                      value={formik.values.motive}
                      disabled={!isHistory}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.motive && Boolean(formik.errors.motive)
                      }
                    />
                  </Grid>
                </Grid>

                {/* <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="alias"
                  name="alias"
                  label="Nombre"
                  value={formik.values.alias}
                  onChange={formik.handleChange}
                  error={formik.touched.alias && Boolean(formik.errors.alias)}
                /> */}
                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={12}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="currency"
                      name="currency"
                      select
                      label="Moneda"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.currency &&
                        Boolean(formik.errors.currency)
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start",
                        },
                      }}
                    >
                      {coins.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.name}>
                            <Box
                              sx={{
                                display: "flex",

                                alignItems: "center",
                              }}
                            >
                              <Box
                                mr={2}
                                component={"img"}
                                src={option.imgURL}
                                sx={{ width: 24, height: 24 }}
                              />
                              {option.name}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={3}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="entryPrice"
                      name="entryPrice"
                      label="Precio de Entrada"
                      type="entryPrice"
                      value={formik.values.entryPrice}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.entryPrice &&
                        Boolean(formik.errors.entryPrice)
                      }
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="stopLoss"
                      name="stopLoss"
                      label="Stop Loss"
                      type="text"
                      value={formik.values.stopLoss}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.stopLoss &&
                        Boolean(formik.errors.stopLoss)
                      }
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp"
                      name="tp"
                      label="tp"
                      type="text"
                      value={formik.values.tp}
                      onChange={formik.handleChange}
                      error={formik.touched.tp1 && Boolean(formik.errors.tp1)}
                    />
                  </Grid>
                </Grid>

                {/* //tp sl */}

                <Grid container justifyContent={"space-between"} mt={1}>
                  <Grid item md={12}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="imageDescription"
                      name="imageDescription"
                      label="Reseña de imagen"
                      type="text"
                      value={formik.values.imageDescription}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.imageDescription &&
                        Boolean(formik.errors.imageDescription)
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                {images.length === 0 ? (
                  <div className=" center m-auto">
                    <Dragndrop
                      onDrop={onDrop}
                      accept={"image/*"}
                      maxFiles={1}
                    />
                  </div>
                ) : (
                  <Preview files={images} removeAll={removeAll} />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                <Button
                  className="w-50"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={preloadedUsers.length === 0}
                >
                  Crear
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddSignals;
