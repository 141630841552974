import React, { useCallback, useState, useEffect } from "react";

import Button from "@mui/material/Button";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";

import { editCourse, uploadCourse } from "../../store/actions/items";
import { storage } from "../../../firebase/firebase-config";

import { Grid, useTheme } from "@mui/material";

const EditCourse = ({ handleOpenCourse, course }) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]); // Clear the added files array
  }, []);

  useEffect(() => {
    // Make sure to revoke the data URIs to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: course.name || "",
      description: course.description || "",
      imageURL: course.imageURL || "",
    },
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = async (values) => {
    const date = new Date();
    // Confirmation dialog
    const confirmed = window.confirm(
      "¿Está seguro que quiere editar este item?"
    );

    if (confirmed) {
      console.log(values);

      // Upload to Firebase Storage
      const file = files[0];
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`courses/${date}`);
      await imageRef.put(file);

      const imageURL = await imageRef.getDownloadURL();

      values.imageURL = imageURL;

      await editCourse(values, course.id);

      // handleOpenCourse(course.id);
    }
  };

  const removeAll = () => {
    setFiles([]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }))
    );
  }, []);
  return (
    <Grid
      item
      xl={3}
      lg={4}
      md={6}
      sm={5}
      xs={12}
      sx={[styles.innerCardStyle, { width: "100%" }]}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={styles.courseCard2} justifyContent={"space-between"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              mb={2}
              color={course.isActive ? theme.palette.primary.main : "grey"}
              variant={"h7"}
              sx={{
                fontWeight: "800",
                textAlign: "center",
                height: "75px",
                textAlign: "center",
                fontSize: "1.2rem",
                [theme.breakpoints.down("sm")]: {
                  fontSize: 14,
                },
              }}
            >
              {course.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: course.isActive
                  ? theme.palette.primary.main
                  : "grey",
              }}
              onClick={() => handleOpenCourse(course.id)}
            >
              <Typography
                mb={2}
                variant={"body1"}
                sx={{
                  fontWeight: "800",

                  [theme.breakpoints.down("sm")]: {
                    fontSize: "10px !important",
                  },
                }}
              >
                Agregar Videos
              </Typography>
              {/* <Box onClick={() => handleOpenCourse(course.id)}>
                <AddIcon />
              </Box> */}
            </Button>
          </Box>
        </Box>
      </form>
    </Grid>
  );
};

export default EditCourse;
