import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { MdDelete } from "react-icons/md";
import { getAllItems } from "../../store/actions/items";
import EditItemModal from "../modals/EditItemModal";
import EnhancedTableHead from "./EnhancedTableHead";
import { styles } from "../../../assets/styles/styles";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const items = [
  {
    id: "1",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
  {
    id: "2",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
  {
    id: "3",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
  {
    id: "4",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
  {
    id: "5",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
  {
    id: "6",
    name: "Nombre",
    description: "lorem ipsum letium ristorum verterium",
    category: 6,
    item_picture: "https://picsum.photos/200",
    video_url: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    original_link: "https://www.youtube.com/watch?v=NrvXPlfLo3c",
    tags: ["home"],
  },
];
const ItemsTable = () => {
  const dispatch = useDispatch();
  // const { items } = useSelector((state) => state.items);
  const isFirstUse = useRef(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("billNumber");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState(5);
  const [products, setproducts] = useState(null);
  // const getItems = useCallback(() => {
  //   dispatch(getAllItems());
  //   setproducts(items);
  // }, []);

  // useEffect(() => {
  //   if (isFirstUse.current) {
  //     getItems();
  //     isFirstUse.current = false;
  //   }
  // }, [getItems]);

  const data = useMemo(() => (items ? items : null), [items]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nombre",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Descripción",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Categoría",
    },
    {
      id: "item_picture",
      numeric: false,
      disablePadding: true,
      label: "Imagen",
    },
    {
      id: "video_url",
      numeric: false,
      disablePadding: true,
      label: "Video",
    },
    {
      id: "original_link",
      numeric: false,
      disablePadding: false,
      label: "Link",
    },
    {
      id: "tags",
      numeric: false,
      disablePadding: false,
      label: "Tag",
    },
    {
      id: "viewMore",
      numeric: false,
      disablePadding: false,
      label: "Editar",
    },
  ];

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const EnhancedTableToolbar = (props) => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Items
        </Typography>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  if (!items) {
    return (
      <div className="container-fluid">
        <div className="category-grid">Loading</div>
      </div>
    );
  }

  const handleDelete = () => {
    MySwal.fire({
      title: "¿Esta seguro que quiere eliminar este item?",
      text: "El item sera eliminado permanentemente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          "Item Eliminado!",
          "El item ha sido eliminado correctamente",
          "success"
        );
      }
    });
  };
  return (
    <Box sx={styles.tableCard}>
      {/* <EnhancedTableToolbar /> */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "small"}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    hover
                    // onClick={(event) => handleClick(event, sale.billNumber)}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="center">{item.description}</TableCell>
                    <TableCell align="center">{item.category}</TableCell>
                    <TableCell align="center" className="table-img">
                      <img src={item.item_picture} alt={item.name} />
                    </TableCell>
                    <TableCell align="center">{item.video_url}</TableCell>
                    <TableCell align="center">{item.original_link}</TableCell>
                    {item.tags.map((tags, key) => (
                      <TableCell key={key} align="center">
                        {tags}
                      </TableCell>
                    ))}

                    <TableCell
                      align="center"
                      className="delete_act text-center  "
                      // onClick={() => handleDelete(item.id)}
                    >
                      <div>
                        <EditItemModal />
                        <IconButton
                          variant="contained"
                          color="error"
                          sx={{ marginLeft: "5px" }}
                          onClick={handleDelete} 
                        >
                          <MdDelete />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 45]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ItemsTable;
