import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdDelete, MdDisabledVisible } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AddAcademy from "../components/AddAcademy";
import {
  deleteFirebaseItem,
  getAllCourses,
  setCourseActivation,
} from "../store/actions/items";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MdEdit } from "react-icons/md";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import AcademyEditModal from "../components/modals/AcademyEditModal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material";

const MySwal = withReactContent(Swal);

const AcademyView = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { courses } = useSelector((state) => state.items);
  const [coursesFromDb, setCoursesFromDb] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const getCoursesFromFb = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllCourses());

      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [courses]);
  useEffect(() => {
    if (isFirstUse.current) {
      getCoursesFromFb();
      isFirstUse.current = false;
    } else {
      setCoursesFromDb(courses);
    }
  }, [courses, getCoursesFromFb]);

  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleActivation = async (item, state) => {
    const action = item.isActive ? "Desactivar" : "Activar";
    const message = `¿Quieres ${action} este curso?`;
    MySwal.fire({
      title: "Guardando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        MySwal.showLoading();
      },
    });
    MySwal.fire({
      title: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: `${action}`,
      cancelButtonText: "Volver",
      confirmButtonColor: theme.palette.primary.primary,
      cancelButtonColor: theme.palette.primary.secondary,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await setCourseActivation(item.id, !item.isActive);

          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          console.error(error);

          MySwal.fire(
            "Error",
            "Hubo un error al activar/desactivar el curso",
            "error"
          );
        } finally {
          refresh();
          MySwal.hideLoading();
        }
      }
    });
  };

  const refresh = () => {
    setloading(true);
    setRefreshing(true);
    getCoursesFromFb();

    setRefreshing(false);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
      },
      filter: false,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired
          // console.log(formattedDate);
          return formattedDate;
        },
      },
    },
    {
      name: "orderNumber",
      label: "Orden",
      options: {
        customBodyRender: (value) => {
          return value ? value : 0;
        },
      },
      filter: false,
    },
    {
      name: "isActive",
      label: "Activo",
      options: {
        customBodyRender: (value) => {
          return value ? "Activo" : "Inactivo";
        },
      },
      filter: false,
    },

    {
      name: "activation",
      label: "Estado",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = courses[dataIndex];

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={() => handleActivation(data)}
            >
              <MdDisabledVisible />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = courses[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const course = courses[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar el curso?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteFirebaseItem(course.id, dataIndex);
                  refresh();
                  Swal.fire("Curso eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting course:", error);
                  Swal.fire("Error al eliminar el curso", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,

    onTableChange: () => setCoursesFromDb(courses),
    onRowsDelete: (rowsToDelete) => {
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = courses[dataIndex];

              // Use an asynchronous function to delete the item
              await deleteFirebaseItem(data.id, dataIndex);
            }
            refresh();

            // Show success message after successful deletion
            Swal.fire("Curso eliminado", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar el curso", "", "error");
          }
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
  };

  if (courses === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Box sx={{ mt: 6 }}>
          <AddAcademy courses={courses} refresh={refresh} />
        </Box>
        <Box sx={styles.cardStyle}>
          {/* <div className="card-header">
            <div>
              <MdPhoto className="category-icon" />
            </div>
            <div>
              <MdSearch className="icon" />
            </div>
            <div className="search">
              <form>
                <input />
              </form>
            </div>
            <div className="ml-auto mr-5  ">
              <IconButton color="success">
                <MdRefresh size={24} />
              </IconButton>
            </div>
          </div> */}

          <Box mt={4}>
            {/* <button onClick={() => refresh()}></button> */}
            <MUIDataTable
              data={coursesFromDb}
              columns={columns}
              options={options}
              components={{ TableBody: BodyComponent }}
            />
          </Box>
          {selectedItem && (
            <AcademyEditModal
              refresh={refresh}
              course={selectedItem}
              open={editModalOpen}
              onClose={handleCloseEditModal}
            />
          )}
        </Box>
      </Container>
    </div>
  );
};

export default AcademyView;
