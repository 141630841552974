import theme from "./darkTheme";

export const styles = {
  white: "#fafafa",
  darkGreen: "#295252",
  cardColor: "#F3F3F3",
  grey: {
    0: "#f3f3f3",
    50: "#F3F6F9",
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  },
  cardStyle: {
    position: "relative",
    // backgroundColor: { xs: "secondary.light", sm: `${white}` },
    backgroundColor: "#4b4b4b",
    width: "100%",
    boxShadow: 3,

    borderRadius: 1,
  },
  wizardCard: {
    position: "relative",
    backgroundColor: "#4b4b4b",
    width: "100%",
    boxShadow: 3,
    minHeight: "75vh",
    borderRadius: 18,
    padding: 20,
    overflowX: "hidden",
    overflowY: "auto",
    mb: 4,
  },

  courseCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    borderRadius: 1,
    backgroundColor: "#2d2d30",
    height: "600px",
    width: "375px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 1.5,
    [theme.breakpoints.down("sm")]: {
      width: 230,
      height: "375px",
    },
  },
  courseCard2: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    borderRadius: 1,
    backgroundColor: "#2d2d30",
    height: "200px",
    width: "200px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 1.5,
    [theme.breakpoints.down("sm")]: {
      height: "175px",
      width: "175px",
    },
  },

  cardTitleStyle: {
    fontWeight: "800 !important",
    fontSize: "1.4rem !important",
  },
  tableCard: {
    width: "95%",
    backgroundColor: "#fafafa",
    boxShadow: 2,
    // p: 3,
    marginBottom: 3,
    borderRadius: "18px",
  },
  innerCardStyle: {
    position: "relative",
    margin: "0px auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 1,
  },
  smallCardStyle: {
    backgroundColor: "#fafafa",
    boxShadow: 3,
    borderRadius: 1,
    p: 1,
    display: "flex",
    flexDirection: "column",
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    maxHeight: 140,
    justifyContent: "center",
  },

  modalThumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    maxHeight: 140,
    justifyContent: "center",
  },

  thumb: {
    display: "inline-flex",
    borderRadius: "24px",
    border: "1px solid #eaeaea",
    marginBottom: 4,
    marginRight: 2,
    width: "100%",
    height: 100,
    padding: 4,
    boxSizing: "border-box",
    justifyContent: "center",
    maxHeight: "85px",
    height: "85px",
    padding: 4,
    boxSizing: "border-box",
    justifyContent: "center",

    margin: "0 auto",
  },
  dropzoneStyle: {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },

  img: {
    display: "block",
    width: "auto",
    height: "100%",
    borderRadius: "24px",
  },

  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: "24px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },

  acceptStyle: {
    borderColor: "#00e676",
  },

  rejectStyle: {
    borderColor: "#ff1744",
  },

  uploadBoxStyle: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  deleteIcon: {
    display: "flex",
    borderRadius: 50,
    maxWidth: 30,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    right: -10,
    top: 0,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: "white",
    cursor: "pointer",
  },
  uploadBoxStyle2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    maxHeight: "120px",
    maxWidth: "250px",
    position: "relative",
  },
  mockupStyle: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "20px",
  },

  editButton: {
    backgroundColor: theme.palette.primary.secondary,
  },
};
