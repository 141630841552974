import * as XLSX from "xlsx";

import {
  MdAdd,
  MdCancel,
  MdCheck,
  MdDelete,
  MdDetails,
  MdEdit,
  MdMore,
  MdRefresh,
  MdVisibility,
} from "react-icons/md";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tab, Tabs, Typography, useTheme } from "@mui/material";
import {
  addMoreRequests,
  checkIfUserExists,
  createUser,
  getAdminInfo,
  getAllRequests,
  getAllUsers,
  getDbRefReq,
  getPendingRequests,
  getRequests,
  rejectUserEp,
  searchNonPendingRequests,
  searchRequests,
  startListeningToRequests,
  startListeningToSpecificRequests,
  updateRequest,
} from "../store/actions/users";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import ChangesSummarySmall from "../components/modals/ChangesSummarySmall.js";
import Container from "@mui/material/Container";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import CustomFooter from "../components/tables/CustomFooter.js";
import CustomSearch from "../components/tables/CustomSearch.js";
import IconButton from "@mui/material/IconButton";
import { IoRefreshOutline } from "react-icons/io5";
import MUIDataTable from "mui-datatables";
import RequestsModal from "../components/modals/RequestsModal";
import Swal from "sweetalert2";
import UpgradeModal from "../components/modals/UpgradeModal.js";
import { db } from "../../firebase/firebase-config.js";
import { deleteFirebaseItem } from "../store/actions/items";
import jwtDecode from "jwt-decode";
import { refreshToken } from "../store/actions/auth";
import { saveAs } from "file-saver";
import { styles } from "../../assets/styles/styles";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export default function RequestsView() {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { requests } = useSelector((state) => state.users);
  const { idToken, user, isAdmin, isSuperAdmin, isAlmighty } = useSelector(
    (state) => state.auth
  );
  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [loadingResults, setLoadingResults] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [admin, setAdmin] = useState();
  const [limit, setLimit] = useState(15);
  const [isSearching, setIsSearching] = useState(false);

  const [dataAvailable, setDataAvailable] = useState(true);
  const [filteredRequests, setFilteredRequests] = useState({
    inclusionRequested: [],
    upgradeRequested: [],
    accepted: [],
    rejected: [],
  });
  const [searchResults, setSearchResults] = useState();
  const [searchType, setSearchType] = useState("email");
  const [searchValue, setSearchValue] = useState("");
  const [noResults, setNoResults] = useState(true);
  const [count, setcount] = useState(30);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getUsersFromFB = useCallback(async () => {
    try {
      setloading(true);
      const adminInfo = await getAdminInfo(user.uid);
      setAdmin(adminInfo);
      dispatch(getPendingRequests());
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 1000);
      console.log(error);
    }
  }, [requests]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (requests?.length > 0) {
      const filtered = {
        inclusionRequested: [],
        upgradeRequested: [],
        accepted: [],
        rejected: [],
      };

      requests.forEach((request) => {
        if (request.status === "pending" && request.selectedAnswer === 1) {
          filtered.inclusionRequested.push(request);
        } else if (
          request.status === "pending" &&
          request.selectedAnswer === 2
        ) {
          filtered.upgradeRequested.push(request);
        } else if (request.status === "accepted") {
          filtered.accepted.push(request);
        } else if (request.status === "rejected") {
          filtered.rejected.push(request);
        }
      });

      setFilteredRequests(filtered);

      setloading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setloading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [requests, getUsersFromFB]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);

        const currentType = getTypeFromTab(tabValue);
        const unsubscribe = dispatch(startListeningToRequests());

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, tabValue, limit]);

  const handleSearch = async () => {
    try {
      setLoadingResults(true);
      const searchedRequests = await searchNonPendingRequests(
        searchType,
        searchValue
      );

      if (searchedRequests.length > 0) {
        setSearchResults(searchedRequests);
        setNoResults(false);
      } else {
        setSearchResults([]);
        setNoResults(true);
      }
    } catch (error) {
      console.error("Error searching requests:", error);
    } finally {
      setLoadingResults(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      setNoResults(true);

      setSearchResults();
    }
  }, [searchValue]);

  const hideSearch = () => {
    setIsSearching(false);
    setSearchValue("");
    // Restablece las solicitudes filtradas a su estado original

    setNoResults(true);
    setSearchResults();
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (!isSearching) {
  //         const currentType = getTypeFromTab(tabValue);
  //         const unsubscribe = dispatch(
  //           startListeningToSpecificRequests(limit, currentType, tabValue)
  //         );
  //         setloading(false);
  //         return unsubscribe;
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [dispatch, tabValue, limit, isSearching]);

  const fetchMoreRequests = (limit, tabValue) => {
    return (dispatch) => {
      const oldItems = requests;
      const lastVisibleDoc = oldItems[oldItems.length - 1]; // Capture the last visible document
      let query = db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .orderBy("cdate", "desc")
        .limit(limit);

      if (lastVisibleDoc) {
        query = query.startAfter(lastVisibleDoc.cdate);
      }

      // Apply filters based on tabValue
      if (tabValue === 0) {
        query = query
          .where("status", "==", "pending")
          .where("selectedAnswer", "==", 1);
      } else if (tabValue === 1) {
        query = query
          .where("status", "==", "pending")
          .where("selectedAnswer", "==", 2);
      } else if (tabValue === 2) {
        query = query.where("status", "==", "accepted");
      } else if (tabValue === 3) {
        query = query.where("status", "==", "rejected");
      }

      const unsubscribe = query.onSnapshot(
        (snapshot) => {
          const newRequests = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const filtered = {
            inclusionRequested: [],
            upgradeRequested: [],
            accepted: [],
            rejected: [],
          };

          newRequests.forEach((request) => {
            if (request.status === "pending" && request.selectedAnswer === 1) {
              filtered.inclusionRequested.push(request);
            } else if (
              request.status === "pending" &&
              request.selectedAnswer === 2
            ) {
              filtered.upgradeRequested.push(request);
            } else if (request.status === "accepted") {
              filtered.accepted.push(request);
            } else if (request.status === "rejected") {
              filtered.rejected.push(request);
            }
          });

          setFilteredRequests((prevFilteredRequests) => ({
            inclusionRequested: [
              ...prevFilteredRequests.inclusionRequested,
              ...filtered.inclusionRequested,
            ],
            upgradeRequested: [
              ...prevFilteredRequests.upgradeRequested,
              ...filtered.upgradeRequested,
            ],
            accepted: [...prevFilteredRequests.accepted, ...filtered.accepted],
            rejected: [...prevFilteredRequests.rejected, ...filtered.rejected],
          }));

          dispatch(addMoreRequests(newRequests)); // Dispatch action to add new requests to Redux store
        },
        (error) => {
          console.error("Error fetching more requests:", error);
        }
      );

      return unsubscribe;
    };
  };

  const getTypeFromTab = (tabValue) => {
    switch (tabValue) {
      case 0:
      case 1:
        return "pending";
      case 2:
        return "accepted";
      case 3:
        return "rejected";
      default:
        return "pending";
    }
  };
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
      console.log("initialized");
      const timeout = setTimeout(() => {
        // setDataAvailable(false);
        setloading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [getUsersFromFB]);

  // console.log(user.uid);

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };
  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };
  const handleOpenUpgradeModal = (item) => {
    setSelectedItem(item);
    setUpgradeModalOpen(true);
  };

  const handleCloseUpgradeModall = () => {
    setSelectedItem(null);
    setUpgradeModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (!dataAvailable) {
        return (
          <CustomBodyWithSpinner loading={dataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, dataAvailable]
  );

  const BodyComponent2 = useMemo(
    () => (tableProps) => {
      if (!noResults) {
        return <CustomBodyWithSpinner loading={noResults} {...tableProps} />;
      }

      return <CustomBodyWithSpinner loading={loadingResults} {...tableProps} />;
    },
    [loadingResults, dataAvailable]
  );

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        filter: false,
      },
    },

    {
      name: "discordId",
      label: "Discord ID",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Correo Electrónico",
      options: {
        filter: false,
      },
    },

    {
      name: "brokerId",
      label: "Broker ID",
      options: {
        filter: false,
      },
    },

    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (!value || !value.seconds) {
            // Handle cases where value is null or undefined
            return "N/A"; // or some default value
          }

          const date = new Date(
            value.seconds * 1000 + (value.nanoseconds || 0) / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
        filter: false,
      },
    },

    {
      name: "foto",
      label: "FOTO",
      options: {
        filter: false,
        sort: false,
        // We'll customize how we pick the row data in `customBodyRenderLite`
        customBodyRenderLite: (dataIndex) => {
          // We need to figure out which subset of requests we’re in:
          let data;
          if (tabValue === 0) {
            data = filteredRequests.inclusionRequested[dataIndex];
          } else if (tabValue === 1) {
            data = filteredRequests.upgradeRequested[dataIndex];
          } else if (tabValue === 2) {
            data = filteredRequests.accepted[dataIndex];
          } else if (tabValue === 3) {
            data = filteredRequests.rejected[dataIndex];
          }

          const hasDepositProof = !!data.depositProofURL;

          return hasDepositProof ? (
            <MdCheck style={{ color: theme.palette.success.main }} />
          ) : (
            <MdCancel style={{ color: theme.palette.error.main }} />
          );
        },
      },
    },

    {
      name: "reason",
      label: "Motivo",
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "changesSummary",
      label: "Ultima modificación",
      options: {
        filter: false,
        sort: false,
        display: tabValue === 0 || tabValue === 1 ? false : true,
        customBodyRenderLite: (dataIndex) => {
          let data;
          if (tabValue === 0) {
            data = filteredRequests.inclusionRequested[dataIndex];
          } else if (tabValue === 1) {
            data = filteredRequests.upgradeRequested[dataIndex];
          } else if (tabValue === 2) {
            data = filteredRequests.accepted[dataIndex];
          } else if (tabValue === 3) {
            data = filteredRequests.rejected[dataIndex];
          }
          const id = data.id;

          return <ChangesSummarySmall id={id} />;
        },
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        customBodyRender: (value) => (
          <span>
            {value === "accepted" ? (
              <Typography>Aceptada</Typography>
            ) : value === "rejected" ? (
              <Typography>Rechazada</Typography>
            ) : value === "pending" ? (
              <Typography>Pendiente</Typography>
            ) : (
              "Pendiente"
            )}
          </span>
        ),
      },
    },

    {
      name: "accept",
      label: "Aceptar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let data;
          if (tabValue === 0) {
            data = filteredRequests.inclusionRequested[dataIndex];
          } else if (tabValue === 1) {
            data = filteredRequests.upgradeRequested[dataIndex];
          } else if (tabValue === 2) {
            data = filteredRequests.accepted[dataIndex];
          } else if (tabValue === 3) {
            data = filteredRequests.rejected[dataIndex];
          }
          const id = data.id;
          const reason = data.reason;
          const status = data.status;

          const handleAccept = () => {
            const userData = {
              name: data.name,
              lastname: data.lastname,
              lastname2: data.lastname2 ?? "",
              email: data.email,
              depositProofURL: data.depositProofURL,
              password: data.password,
              phone: data.phone,
              discordId: data.discordId,
              brokerId: data.brokerId,
              tier: 1,
            };
            // console.log(userData);
            Swal.fire({
              title: "Aceptar Solicitud",
              text: "¿Está seguro que desea aceptar la solicitud?",
              confirmButtonText: "Aceptar",
              showDenyButton: true,
              denyButtonText: "Cancelar",
            }).then(async (result) => {
              if (result.isConfirmed) {
                MySwal.fire({
                  icon: "warning",
                  title: "Guardando...",
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                  showConfirmButton: false,
                });

                const userCheckResult = await checkIfUserExists(data.brokerId);
                if (userCheckResult.exists && !userCheckResult.isDisabled) {
                  MySwal.fire(
                    "Ya existe un usuario con el mismo Broker Id",
                    "",
                    "error"
                  );
                } else {
                  try {
                    if (isValidToken(idToken)) {
                      const res = await createUser(userData, idToken);
                      if (res.ok) {
                        await updateRequest(id, "accepted", admin.name, reason);
                        MySwal.fire(
                          `Usuario ${
                            data.name + " " + data.lastname
                          } creado con el correo ${data.email}  `,
                          "",
                          "success"
                        );
                      } else if (res?.error?.code === 11) {
                        MySwal.fire("El correo ya existe", "", "error");
                      }
                    } else {
                      const newToken = await refreshToken();
                      const res = await createUser(userData, newToken);

                      if (res.ok) {
                        await updateRequest(id, "accepted", admin.name, reason);
                        MySwal.fire(
                          `Usuario ${
                            data.name + " " + data.lastname
                          } creado con el correo ${data.email} `,
                          "",
                          "success"
                        );
                      } else if (res?.error?.code === 11) {
                        MySwal.fire("El correo ya existe", "", "error");
                      }
                    }
                  } catch (error) {
                    console.error("Error creating user:", error);

                    MySwal.fire(
                      "Hubo un error al guardar el usuario",
                      "",
                      "error"
                    );
                  }
                }
                handleRefresh();
              } else if (result.dismiss === "cancel") {
                MySwal.fire("Cambios no guardados", "", "info");
                handleRefresh();
              }
            });
          };

       
          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.success.main }}
              onClick={handleAccept}
              disabled={data.status === "accepted"}
            >
              <MdCheck />
            </IconButton>
          );
        },
        display: tabValue === 0 ? true : false,
        filter: false,
      },
    },
    {
      name: "reject",
      label: "Rechazar",

      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let data;
          if (tabValue === 0) {
            data = filteredRequests.inclusionRequested[dataIndex];
          } else if (tabValue === 1) {
            data = filteredRequests.upgradeRequested[dataIndex];
          } else if (tabValue === 2) {
            data = filteredRequests.accepted[dataIndex];
          } else if (tabValue === 3) {
            data = filteredRequests.rejected[dataIndex];
          }
          const id = data.id;
          const status = data.status;
          const reason = data.reason;
          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: `¿Está seguro que desea rechazar la solicitud?`,
              confirmButtonText: "Rechazar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const loadingSwal = MySwal.fire({
                  title: "Guardando...",
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                try {
                  if (isValidToken(idToken)) {
                    const res = await rejectUserEp(data.email, idToken);

                    if (res.ok) {
                      MySwal.fire(`Solicitud Rechazada `, "", "success");
                      await updateRequest(id, "rejected", admin.name, reason);
                      handleRefresh();
                    } else if (res?.error?.code === 11) {
                      MySwal.fire("Hubo un  error", "", "error");
                    }
                  } else {
                    const newToken = await refreshToken();
                    const res = await rejectUserEp(data.email, newToken);
                    await updateRequest(id, "rejected", admin.name, reason);
                    if (res.ok) {
                      MySwal.fire(`Solicitud Rechazada `, "", "success");

                      handleRefresh();
                    } else if (res?.error?.code === 11) {
                      MySwal.fire("Hubo un  error", "", "error");
                    }
                  }
                  handleRefresh();
                  MySwal.fire("Solicitud Denegada!", "", "success");
                  loadingSwal.close();
                } catch (error) {
                  console.error("Error deleting request:", error);
                  loadingSwal.close();
                  MySwal.fire(
                    "Hubo un error al rechazar la solicitud",
                    "",
                    "error"
                  );
                }
              }
            });
          };

          // Display the delete button for rejected status
          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleDelete}
              disabled={data.status === "rejected"}
            >
              <MdCancel />
            </IconButton>
          );
        },
        display: tabValue === 0 || tabValue === 1 ? true : false,
        filter: false,
      },
    },
    // {
    //   name: "delete",
    //   label: "Eliminar",
    //   options: {
    //     empty: true,
    //     customBodyRenderLite: (dataIndex) => {
    //       let data;
    //       if (tabValue === 0) {
    //         data = filteredRequests.all[dataIndex];
    //       } else if (tabValue === 1) {
    //         data = filteredRequests.pending[dataIndex];
    //       } else if (tabValue === 2) {
    //         data = filteredRequests.accepted[dataIndex];
    //       } else if (tabValue === 3) {
    //         data = filteredRequests.rejected[dataIndex];
    //       }

    //       const id = data.id;
    //       const status = data.status;

    //       const handleDelete = () => {
    //         Swal.fire({
    //           title: "Alerta",
    //           text: `¿Está seguro que desea eliminar la solicitud?`,
    //           confirmButtonText: "Eliminar",
    //           showDenyButton: true,
    //           denyButtonText: "Volver",
    //         }).then(async (result) => {
    //           if (result.isConfirmed) {
    //             const loadingSwal = MySwal.fire({
    //               title: "Guardando...",
    //               showConfirmButton: false,
    //               allowOutsideClick: false,
    //               didOpen: () => {
    //                 MySwal.showLoading();
    //               },
    //             });
    //             try {
    //               await deleteRequest(id);

    //               MySwal.fire("Solicitud Eliminada!", "", "success");
    //               loadingSwal.close();
    //               handleRefresh();
    //             } catch (error) {
    //               console.error("Error deleting request:", error);
    //               loadingSwal.close();
    //               MySwal.fire(
    //                 "Hubo un error al eliminar la solicitud",
    //                 "",
    //                 "error"
    //               );
    //             }
    //           }
    //         });
    //       };

    //       // Display the delete button for rejected status
    //       return (
    //         <IconButton
    //           sx={{ backgroundColor: theme.palette.error.main }}
    //           onClick={handleDelete}
    //         >
    //           <MdDelete />
    //         </IconButton>
    //       );
    //     },
    //     filter: false,
    //   },
    // },
    {
      name: "edit",
      label: "Ver",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let data;
          if (tabValue === 0) {
            data = filteredRequests.inclusionRequested[dataIndex];
          } else if (tabValue === 1) {
            data = filteredRequests.upgradeRequested[dataIndex];
          } else if (tabValue === 2) {
            data = filteredRequests.accepted[dataIndex];
          } else if (tabValue === 3) {
            data = filteredRequests.rejected[dataIndex];
          }

          const handleEdit = () => {
            if (data.reason === "Es mi primer ingreso a Revolution") {
              handleOpenEditModal(data);
            } else {
              handleOpenUpgradeModal(data);
            }
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={handleEdit}
            >
              <MdVisibility />
            </IconButton>
          );
        },
        filter: false,
      },
    },
  ];
  const columnsSearch = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        filter: false,
      },
    },

    {
      name: "discordId",
      label: "Discord ID",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Correo Electrónico",
      options: {
        filter: false,
      },
    },

    {
      name: "brokerId",
      label: "Broker ID",
      options: {
        filter: false,
      },
    },

    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (!value || !value.seconds) {
            // Handle cases where value is null or undefined
            return "N/A"; // or some default value
          }

          const date = new Date(
            value.seconds * 1000 + (value.nanoseconds || 0) / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
        filter: false,
      },
    },

    {
      name: "reason",
      label: "Motivo",
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "changesSummary",
      label: "Ultima modificación",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRenderLite: (dataIndex) => {
          const data = searchResults[dataIndex];

          if (data && data.id) {
            const id = data.id;
            return <ChangesSummarySmall id={id} />;
          } else {
            // Handle the case where data or data.id is undefined
            return <div>Error: Data or ID not found</div>;
          }
        },
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        customBodyRender: (value) => (
          <span>
            {value === "accepted" ? (
              <Typography>Aceptada</Typography>
            ) : value === "rejected" ? (
              <Typography>Rechazada</Typography>
            ) : value === "pending" ? (
              <Typography>Pendiente</Typography>
            ) : (
              "Pendiente"
            )}
          </span>
        ),
      },
    },

    {
      name: "edit",
      label: "Ver",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = searchResults[dataIndex];

          const handleEdit = () => {
            if (data.reason === "Es mi primer ingreso a Revolution") {
              handleOpenEditModal(data);
            } else {
              handleOpenUpgradeModal(data);
            }
          };

          // Display the "Ver" button
          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={handleEdit}
            >
              <MdVisibility />
            </IconButton>
          );
        },
        display: true, // Always display the "Ver" button
        filter: false,
      },
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",

    rowsPerPage: 15,

    // onTableChange: handleTableChange,
    search: true,
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      // console.log(values.forEach((val) => console.log(val)));

      const json = values.map((val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].name] = v;
        });
        return temp;
      });

      const fileName = "Solicitudes"; // Declare fileName here
      const ws = XLSX.utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default CSV download from the table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
        downloadCsv: "Download XLSX",
      },
    },

    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };
  const optionsSearch = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",

    rowsPerPage: 15,

    // onTableChange: handleTableChange,
    search: true,

    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
        downloadCsv: "Download XLSX",
      },
    },

    // customToolbar: () => {
    //   return <CustomToolbar onRefresh={handleRefresh} />;
    // },
  };
  return (
    <div id="tab-content">
      <Container maxWidth="xl">
        <div className="divider"></div>
        {/* <Box sx={{ mt: 6 }}>
          <AddUsers refresh={handleRefresh} isAdmin={isAdmin} />
        </Box> */}
        {/* <Box mt={2}>
          <CustomSearch
            searchType={searchType}
            setSearchType={setSearchType}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            hideSearch={hideSearch}
          />
        </Box> */}
        <Box sx={styles.cardStyle} className="content theme-card" mt={6}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.primary.main },
            }}
          >
            <Tab
              label="Solicitud del App"
              style={
                tabValue === 0
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Solicitud de mejora"
              style={
                tabValue === 1
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            {/* <Tab
              label="Aceptadas"
              style={
                tabValue === 2
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Rechazadas"
              style={
                tabValue === 3
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            /> */}
          </Tabs>

          <div className="table-wrapper  ">
            {tabValue === 0 && (
              <MUIDataTable
                data={filteredRequests.inclusionRequested}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 1 && (
              <MUIDataTable
                data={filteredRequests.upgradeRequested}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}

            {/* {tabValue === 2 && (
              <MUIDataTable
                data={filteredRequests.accepted}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 3 && (
              <MUIDataTable
                data={filteredRequests.rejected}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )} */}
          </div>
        </Box>
        <Box my={6}>
          <CustomSearch
            searchType={searchType}
            setSearchType={setSearchType}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            hideSearch={hideSearch}
          />
        </Box>
        <MUIDataTable
          data={searchResults}
          columns={columnsSearch}
          options={optionsSearch}
          components={{ TableBody: BodyComponent2 }}
        />
        {selectedItem && (
          <RequestsModal
            item={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={handleRefresh}
            admin={admin}
          />
        )}
        {selectedItem && (
          <UpgradeModal
            item={selectedItem}
            open={upgradeModalOpen}
            onClose={handleCloseUpgradeModall}
            refresh={handleRefresh}
            admin={admin}
          />
        )}
      </Container>
    </div>
  );
}
