import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import { MdArrowDropDown, MdArrowUpward, MdExpand } from "react-icons/md";
import { ExpandMore } from "@mui/icons-material";
import { getChangesFromRequest } from "../../store/actions/users";

const ChangesSummary = ({ item }) => {
  const [observations, setObservations] = useState([]);

  const fetchObservations = useCallback(async () => {
    const observationsData = await getChangesFromRequest(item.id);
    setObservations(observationsData);
  }, [item]);
  useEffect(() => {
    fetchObservations();
  }, [fetchObservations, item]);

  // Function to compare cdate for sorting
  const compareByDate = (a, b) => {
    return new Date(b.cdate) - new Date(a.cdate);
  };

  return (
    <Grid container item spacing={2} md={12}>
      {/* <Grid item xs={6}>
        <Typography mr={4} mb={1}>
          {observations?.text} {observations?.admin}
        </Typography>
      </Grid> */}

      <Grid item xs={12}>
        <Grid item xs={12}>
          {observations
            .sort(compareByDate) // Sort observations by cdate
            .map((observation, index) => (
              <AccordionSummary
                sx={{ backgroundColor: "#4B4B4B", borderRadius: "12px" }}
              >
                <Typography>
                  {`${observation.admin} - ${observation.cdate
                    .toDate()
                    .toLocaleDateString("es-AR")}`}{" "}
                  {observation?.text !== "" ? observation?.text : "--"}
                </Typography>
              </AccordionSummary>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangesSummary;
