import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddNotifications from "./../components/AddNotifications";

import NotificationsEditModal from "../components/modals/NotificationsEditModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifications } from "../store/actions/notifications";
import {
  callDeleteAlert,
  deleteFirebaseNotification,
} from "../store/actions/items";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import { Box, IconButton, useTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { MdDelete } from "react-icons/md";
import { deleteNotification } from "../store/actions/signals";
import Swal from "sweetalert2";
import { startListeningToUsers } from "../store/actions/users";
import { IoRefreshOutline } from "react-icons/io5";
export default function NotificationsView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.notifications);
  const { users } = useSelector((state) => state.users);
  const [notificationsFromDB, setNotificationsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const unsubscribe = dispatch(startListeningToUsers());

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [dispatch]);

  const getNotificationsFromDB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllNotifications());
      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [notifications]);
  useEffect(() => {
    if (isFirstUse.current) {
      getNotificationsFromDB();
      isFirstUse.current = false;
    }
  }, [getNotificationsFromDB]);

  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };
  const refresh = () => {
    getNotificationsFromDB();
  };
  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };
  const handleRefresh = () => {
    setloading(true);
    getNotificationsFromDB();
    // setTimeout(() => {
    //   setloading(false);
    // }, 1000);
  };
  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const initDeleteProduct = (elementsToDelete) => {
    const rows = elementsToDelete.data;

    rows.forEach((element) => {
      const index = element.dataIndex;
      const productToDelete = notifications[index];
      dispatch(deleteFirebaseNotification(productToDelete.id, index));
    });
  };
  const columns = [
    {
      name: "notification",
      label: "Título",
      options: {
        customBodyRender: (value) => {
          const title = value?.title;

          return title;
        },
      },
    },

    {
      name: "notification",
      label: "Descripción",
      options: {
        customBodyRender: (value) => {
          const body = value?.body;

          return body;
        },
      },
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired
          // console.log(formattedDate);
          return formattedDate;
        },
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = notifications[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar la señal?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteNotification(data.id, dataIndex);
                  refresh();
                  Swal.fire("Señal eliminada", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar la señal", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },

    // {
    //   name: "edit",
    //   label: "Editar",
    //   options: {
    //     empty: true,
    //     customBodyRenderLite: (dataIndex) => {
    //       const data = notifications[dataIndex];

    //       // console.log("schedules:", schedules);
    //       // console.log("dataIndex:", dataIndex);
    //       // console.log("schedule:", schedule);

    //       const handleEdit = () => {
    //         console.log(" edit button");
    //         handleOpenEditModal(data);
    //       };

    //       return (
    //         <IconButton
    //           sx={{ backgroundColor: theme.palette.primary.secondary }}
    //           onClick={handleEdit}
    //         >
    //           <MdEdit />
    //         </IconButton>
    //       );
    //     },
    //   },
    //   filter: false,
    // },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    onTableChange: () => setNotificationsFromDB(notifications),
    onRowsDelete: (rowsToDelete) => {
      callDeleteAlert(
        rowsToDelete,
        initDeleteProduct,
        getNotificationsFromDB,
        "Notificación eliminada"
      );

      return false;
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  // if (notifications === null || loading) {
  //   return <div>loading</div>;
  // }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Grid container spacing={3} justifyContent="center">
          <Grid item sx={{ mt: 6 }}>
            <AddNotifications users={users} />
          </Grid>
          {/* <Grid item sx={{ mt: 6 }}>
          <AddWeeklyNotifications />
        </Grid> */}
        </Grid>
        <Box sx={styles.cardStyle} className="content theme-card">
          <div className="table-wrapper  ">
            <MUIDataTable
              data={notificationsFromDB}
              columns={columns}
              options={options}
              components={{ TableBody: BodyComponent }}
            />
          </div>
        </Box>
        {selectedItem && (
          <NotificationsEditModal
            item={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
          />
        )}
      </Container>
    </div>
  );
}
