import { db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../variables/globals";
export const SET_NOTIFICATIONS = "SET_SIGNALS";
export const SET_IS_LOADING = "SET_IS_LOADING";

export const getAllNotifications = () => {
  return (dispatch) => {
    const notifications = [];
    dispatch(isLoading(true));
    db.collection("notifications")
      .get()
      .then(async (itemsFromFs) => {
        // console.log("success");
        itemsFromFs.docs.forEach((item) =>
          notifications.push({ id: item.id, ...item.data() })
        );
        dispatch(getNotifications(notifications));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};

export const postPushNotificationAll = async (data, token) => {
  const url = API_URL + "/notifications/pushChunks";

  const MAX_TOKENS_PER_MESSAGE = 500;
  const chunks = [];
  for (let i = 0; i < data.pushTokens.length; i += MAX_TOKENS_PER_MESSAGE) {
    chunks.push(data.pushTokens.slice(i, i + MAX_TOKENS_PER_MESSAGE));
  }

  const body = {
    title: data.title,
    body: data.text,
    type: data.type,
    audience: data.audience,
    pushTokens: chunks,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const postPushNotificationForced = async (data, token) => {
  // const MAX_TOKENS_PER_MESSAGE = 500;
  // const chunks = [];
  // for (let i = 0; i < tokensToSend.length; i += MAX_TOKENS_PER_MESSAGE) {
  //   chunks.push(tokensToSend.slice(i, i + MAX_TOKENS_PER_MESSAGE));
  // }
  const url = API_URL + "/notifications/forcedPush";

  const body = {
    title: data.title,
    body: data.text,
    type: "regular",
    audience: data.audience,
    pushTokens: data.pushTokens,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const postPushNotificationActive = async (data, token) => {
  const url = API_URL + "/notifications/push/active";

  const body = {
    title: data.title,
    body: data.text,
    type: data.type ?? "",
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const postPushNotificationGold = async (data, token) => {
  const url = API_URL + "/notifications/push/gold";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      title: data.title,
      body: data.text,
      type: data.type ?? "",
    }),
  };

  try {
    const response = await fetch(url, options);

    const res = await response.json();
    console.log(" gold response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const pushNotificationSignal = async (data, token) => {
  const url = API_URL + "/pushToActive";

  const body = {
    title: " Señal de Cryptomoneda",
    body: `NUEVA SEÑAL! `,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
// export const pushNotificationAll = () => {
//   const url = API_URL + "/pushNotifications";

//   const body = {
//     title: " Señal de Cryptomoneda 🚀",
//     body: `NUEVA SEÑAL! `,
//   };
//   return this.http.post(url, body);
// };
// export const pushNotificationSignal = (item) => {
//   const url = API_URL + "/pushToActive";

//   const body = {
//     title: " Señal de Cryptomoneda",
//     body: `NUEVA SEÑAL! `,
//   };
//   return this.http.post(url, body);
// };
export const addNotificationToFB = async (data) => {
  console.log(data);
  const notificationRef = db.collection("notifications");
  await notificationRef.add({
    notification: {
      title: data.title ?? "",
      body: data.text ?? "",
    },
    cdate: new Date(),
    isMembersOnly: false,
    isGoldPlan: false,
    type: data.type,
  });
};

export const getNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});
export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
