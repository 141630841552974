import React from "react";
import LineGraph from "./../components/charts/LineGraph";
import VerticalGraph from "./../components/charts/VerticalGraph";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RecentSales from "./../components/widgets/RecentSales";
import PieGraph from "../components/charts/PieGraph";
import { styles } from "./../../assets/styles/styles";
import Container from "@mui/material/Container";

// Ajustar margenes con respecto a otras views
const white = "#fafafa";


const chipStyle = {
  // mt: 2,
  // ml: 2,
  // width: { sm: 200, md: 300 },
  // backgroundColor: { xs: "secondary.light", sm: `${white}` },
  backgroundColor: `${white}`,
  color: `${styles.grey[600]}`,
  boxShadow: 3,
  borderRadius: 1,
  // background:"white"
};

const smallCardStyle = {
  backgroundColor: `${white}`,
  boxShadow: 3,
  borderRadius: 1,
  p: 1,
  display: "flex",
  flexDirection: "column",
};
const multiCardStyle = {
  paddingLeft: "0px !important",
  display: "flex",
  flexDirection: "column",
maxHeight:"420px !important",
  justifyContent: "space-between",
};
const gridStyle = {
  width: "100%",
  justifyContent: "space-between",  
  marginLeft: 0,
};

const Dashboard = () => {
  return (
    <div id="dashboard">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        {/* <Grid sx={gridStyle} container spacing={3} >
          <Grid
            item
            xs={12}
            md={12}
            lg={5.5}
            sx={{ paddingLeft: "0px !important" }}
          >
            <Box sx={styles.cardStyle} className="p-4 theme-card ">
              <LineGraph />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={2.5} sx={multiCardStyle} >
            <Box sx={smallCardStyle} className=" theme-card ">
              <RecentSales />
            </Box>
            <Box sx={smallCardStyle} className=" theme-card ">
              <RecentSales />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={2.5} sx={multiCardStyle}>
            <Box sx={smallCardStyle} className=" theme-card ">
              <RecentSales />
            </Box>
            <Box sx={smallCardStyle} className=" theme-card ">
              <RecentSales />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            lg={5.5}
            sx={{ paddingLeft: "0px !important" }}
          >
            <Box sx={styles.cardStyle} className="p-4 info theme-card ">
              <div>PIE CHART</div>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            lg={6}
            sx={{ paddingLeft: "0px !important" }}
          >
            <Box sx={styles.cardStyle} className="p-4 theme-card ">
              <VerticalGraph />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={12}
            sx={{ paddingLeft: "0px !important" }}
          >
        
          </Grid>
        </Grid> */}
      </Container>
    </div>
  );
};

export default Dashboard;
