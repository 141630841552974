import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdSearch, MdPhoto, MdRefresh, MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import ItemsTable from "../components/tables/ItemsTable";
import IconButton from "@mui/material/IconButton";
import AddSignals from "./../components/AddSignals";
import { deleteFSignal, getAllSignals } from "../store/actions/signals";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";

import { MdEdit } from "react-icons/md";
import MUIDataTable from "mui-datatables";
import SignalsEditModal from "../components/modals/SignalsEditModal";
import { useTheme } from "@mui/material";
import Swal from "sweetalert2";
import { IoRefreshOutline } from "react-icons/io5";
import { startListeningToUsers } from "../store/actions/users";

export default function SignalsView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { signals } = useSelector((state) => state.signals);
  const { users } = useSelector((state) => state.users);
  const [signalsFromDB, setSignalsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const unsubscribe = dispatch(startListeningToUsers());

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [dispatch]);

  const getSignalsFromDB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllSignals());
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [signals]);
  useEffect(() => {
    if (isFirstUse.current) {
      getSignalsFromDB();
      isFirstUse.current = false;
    }
  }, [getSignalsFromDB]);

  const refresh = () => {
    getSignalsFromDB();
  };
  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };
  const handleRefresh = () => {
    setloading(true);
    getSignalsFromDB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };
  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const initDeleteProduct = (elementsToDelete) => {
    const rows = elementsToDelete.data;

    rows.forEach(async (element) => {
      const index = element.dataIndex;
      const productToDelete = signals[index];
      await deleteFSignal(productToDelete.id, index);
    });
  };
  const columns = [
    {
      name: "currency",
      label: "Moneda",
    },
    {
      name: "picture",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
      },
      filter: false,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        display: true,
      },
    },
    {
      name: "category",
      label: "Categoría",
      options: {
        display: true,
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = signals[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = signals[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar la señal?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteFSignal(data.id, dataIndex);
                  refresh();
                  Swal.fire("Señal eliminada", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar la señal", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    onTableChange: () => setSignalsFromDB(signals),
    onRowsDelete: (rowsToDelete) => {
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar la señal?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = signals[dataIndex];

              // Use an asynchronous function to delete the item
              await deleteFSignal(data.id, dataIndex);
            }

            refresh();
            // Show success message after successful deletion
            Swal.fire("Señal eliminada", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar la señal", "", "error");
          }
        } else if (result.isDenied) {
          // Handle the "Volver" button action (if needed)
          console.log("Volver button clicked");
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  if (signals === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Box sx={{ mt: 6 }}>
          <AddSignals refresh={refresh} users={users} />
        </Box>
        <Box sx={styles.cardStyle} className="content theme-card">
          <div className="table-wrapper  center mt-4">
            <MUIDataTable
              data={signals}
              columns={columns}
              options={options}
              components={{ TableBody: BodyComponent }}
            />
          </div>
        </Box>
        {selectedItem && (
          <SignalsEditModal
            signal={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={refresh}
            users={users}
          />
        )}
      </Container>
    </div>
  );
}
