import {
  MdSignalCellular1Bar,
  MdPhoto,
  MdCastForEducation,
  MdPriorityHigh,
  MdHome,
  MdSupervisedUserCircle,
  MdEditCalendar,
} from "react-icons/md";

export const menu = [
  {
    id: "1",
    route: "/home",
    title: "Inicio",
    icon: <MdHome />,
  },
  {
    id: "2",
    route: "/items",
    title: "Banners",
    icon: <MdHome />,
  },
  {
    id: "3",
    title: "Contenido",
    icon: <MdHome />,
    submenu: [
      {
        route: "/academy",
        title: "Academia",
        icon: <MdCastForEducation />,
      },
    ],
  },

  {
    id: "4",
    title: "Notificaciones",
    icon: <MdPriorityHigh />,
    submenu: [
      {
        route: "/notifications",
        title: " Push",
        icon: <MdPriorityHigh />,
      },
      {
        route: "/weekly-notifications",
        title: "Semanales",
        icon: <MdPriorityHigh />,
      },
    ],
  },

  {
    id: "5",
    route: "/schedules",
    title: "Agendas",
    icon: <MdEditCalendar />,
  },

  {
    id: "6",
    route: "/users",
    title: "Control de Usuarios",
    icon: <MdSupervisedUserCircle />,
  },
  {
    id: "6",
    route: "/requests",
    title: "Solicitudes de registro",
    icon: <MdSupervisedUserCircle />,
  },
  {
    id: "7",
    route: "/signals",
    title: "Señales",
    icon: <MdSignalCellular1Bar />,
  },
];
