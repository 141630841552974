// VideoDetails.js

import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { editVideo, uploadVideo } from "../../store/actions/items";
const MySwal = withReactContent(Swal);
const VideoDetails = ({ data, courseId, refresh }) => {
  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data.name ?? "",
      videoURL: data.videoURL || "",
      category: data.category || "",
      cdate: data.cdate || "",
      duration: data.duration || "",
      isGoldPlan: data.isGoldPlan || false,
      isMembersOnly: data.isMembersOnly || false,
      tags: data.tags || [], // Remove the extra array here
      usersCount: data.usersCount || 0,
      orderNumber: data.orderNumber || 0,
    });
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: data.name ?? "",
      videoURL: data.videoURL || "",
      category: data.category || "",
      cdate: data.cdate || "",
      duration: data.duration || "",
      isGoldPlan: data.isGoldPlan || false,
      isMembersOnly: data.isMembersOnly || false,
      tags: data.tags || [], // Remove the extra array here
      usersCount: data.usersCount || 0,
      orderNumber: data.orderNumber || 0,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        SubmitForm(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const SubmitForm = async () => {
    MySwal.fire({
      title: "¿Confirmar?",
      text: "¿Está seguro que quiere editar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        try {
          const editedData = {
            name: formik.values.name,
            videoURL: formik.values.videoURL.includes("youtube")
              ? formik.values.videoURL + "&"
              : formik.values.videoURL,
            category: formik.values.category,
            cdate: formik.values.cdate,
            duration: formik.values.duration,
            tags: formik.values.tags,
            usersCount: formik.values.usersCount,
            orderNumber: formik.values.orderNumber,
          };

          // Pass the videoId to uploadVideo function
          await editVideo(courseId, editedData, data.id);

          // Fetch updated videos data
          //   const { videos } = await getCourse(courseId);
          //   setVideosFromFb(videos);

          refresh();
          MySwal.fire("Video Editado!", "", "success");
        } catch (error) {
          console.error("Error submitting form:", error);
          Swal.fire("Error", "Ocurrió un error al editar el video.", "error");
        }
      }
    });
  };

  return (
    <>
      <Box sx={{ width: "75%" }}>
        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="name"
          name="name"
          label="Nombre del video"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
        />

        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="videoURL"
          name="videoURL"
          label="Video URL"
          type="text"
          value={formik.values.videoURL}
          onChange={formik.handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="description"
          name="description"
          label="Descripción"
          type="text"
          value={formik.values.description}
          onChange={formik.handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="tags"
          name="tags"
          label="Sección"
          type="text"
          value={formik.values.tags}
          onChange={formik.handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="duration"
          name="duration"
          label="Duración del video"
          type="text"
          value={formik.values.duration}
          onChange={formik.handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          id="orderNumber"
          name="orderNumber"
          label="Número de Orden"
          type="number"
          value={formik.values.orderNumber}
          onChange={formik.handleChange}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "20px",
            marginBottom: "5px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            sx={{ width: "auto", margin: "0 auto" }}
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            onClick={formik.handleSubmit}
          >
            Guardar Cambios
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default VideoDetails;
