import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFirebaseItem } from "../store/actions/items";
import { refreshToken } from "../store/actions/auth";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MUIDataTable, { TableFooter } from "mui-datatables";
import { MdAdd, MdCheck, MdDelete, MdEdit } from "react-icons/md";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import AddUsers from "../components/AddUsers.js";
import {
  activateUser,
  addMoreUsers,
  deactivateUser,
  deleteUser,
  fetchMoreUsers,
  getAllUsers,
  getDbRef,
  getUsers,
  searchUsers,
  startListeningToUsers,
} from "../store/actions/users";
import UsersEditModal from "../components/modals/UsersEditModal";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { IoRefreshOutline } from "react-icons/io5";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { FaCheck, FaTimes } from "react-icons/fa";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CustomSearch from "../components/tables/CustomSearch.js";
import { db } from "../../firebase/firebase-config.js";
import CustomFooter from "../components/tables/CustomFooter.js";

const MySwal = withReactContent(Swal);
export default function UsersView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { idToken, user, isAdmin, isSuperAdmin, isAlmighty } = useSelector(
    (state) => state.auth
  );
  const [usersFromDb, setUsersFromDB] = useState([]);

  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(30);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchType, setSearchType] = useState("email");
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(15); // Initial limit
  const [totalCount, setTotalCount] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  // useEffect(() => {
  //   console.log(isSuperAdmin);
  // }, [isSuperAdmin]);
  // const handleDownload = (buildHead, buildBody, columns, data) => {
  //   // Implement your custom download logic here
  //   const fileType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";

  //   const json = data.map((item) => columns.map((col) => item[col.name]));

  //   const ws = XLSX.utils.json_to_sheet(json, {
  //     header: columns.map((col) => col.label),
  //   });
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataBlob = new Blob([excelBuffer], { type: fileType });
  //   saveAs(dataBlob, `Users${fileExtension}`);
  // };

  const getCount = async () => {
    const tCount = await getDbRef();
    if (!isSearching) {
      setTotalCount(tCount);
    }
  };
  useEffect(() => {
    getCount();
  }, [isSearching]);

  const handleSearch = async () => {
    try {
      setloading(true);
      setIsSearching(true);
      const searchedUsers = await searchUsers(searchType, searchValue);
      setUsersFromDB(searchedUsers);
      if (searchedUsers.length === 0) {
        setTotalCount(0);
      } else {
        setTotalCount(searchedUsers.length);
      }
    } catch (error) {
      console.error("Error searching users:", error);
    } finally {
      setloading(false);
    }
  };

  const hideSearch = () => {
    setIsSearching(false);
    setSearchType("email");
    setSearchValue("");
    setUsersFromDB(users);
  };

  const getUsersFromFB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllUsers());
      setUsersFromDB(users);
      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 1000);
      console.log(error);
    }
  }, [users]);

  // console.log(user.uid);
  // useEffect(() => {
  //   if (isFirstUse.current) {
  //     getUsersFromFB();
  //     isFirstUse.current = false;
  //   }
  // }, [getUsersFromFB]);

  // console.log(user.uid);
  // useEffect(() => {
  //   if (isFirstUse.current) {
  //     getUsersFromFB();
  //     isFirstUse.current = false;
  //   }
  // }, [getUsersFromFB]);

  useEffect(() => {
    const unsubscribe = dispatch(startListeningToUsers(limit));

    return () => unsubscribe();
  }, [dispatch, limit]);

  const fetchMoreUsers = (limit) => {
    return (dispatch, getState) => {
      const oldItems = users;
      const lastVisibleDoc = oldItems[oldItems.length - 1];
      let query = db.collection("users").orderBy("cdate", "desc").limit(limit);

      if (lastVisibleDoc) {
        query = query.startAfter(lastVisibleDoc.cdate);
      }

      const unsubscribe = query.onSnapshot(
        (snapshot) => {
          const newUsers = [];
          snapshot.docs.forEach((item) =>
            newUsers.push({ id: item.id, ...item.data() })
          );
          console.log(usersFromDb);
          setUsersFromDB((prevUsers) => [...prevUsers, ...newUsers]);

          dispatch(addMoreUsers(newUsers));
          console.log(users);
        },
        (error) => {
          console.error("Error listening to users:", error);
        }
      );

      return unsubscribe;
    };
  };

  useEffect(() => {
    setUsersFromDB(users);

    setloading(false);
  }, [users]);
  const handleLoadMore = () => {
    dispatch(fetchMoreUsers(limit));
  };
  const handleRefresh = () => {
    getUsersFromFB();
  };
  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const columns = [
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname2",
      label: "Segundo Apellido",
      options: {
        filter: false,
      },
    },
    {
      name: "discordId",
      label: "Discord ID",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Correo Electrónico",
      options: {
        filter: false,
      },
    },
    {
      name: "discordId",
      label: "Discord ID",
      options: {
        filter: false,
      },
    },
    {
      name: "discordActive",
      label: "Discord Activo",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span>
            {value ? (
              <FaCheck size={24} style={{ color: "green" }} /> // Icon for active
            ) : (
              <FaTimes size={24} style={{ color: "red" }} /> // Icon for inactive
            )}
          </span>
        ),
      },
    },
    {
      name: "brokerId",
      label: "Broker ID",
      options: {
        filter: false,
      },
    },

    {
      name: "isActive",
      label: "Intermedio",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "isGoldPlan",
      label: "Avanzado",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "isDisabled",
      label: "Deshabilitado",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "nivel",
      label: "Nivel",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta.rowData;
          const isActive = rowData[8];
          const isGoldPlan = rowData[9];

          // Determine if the user is disabled
          const isDisabled = rowData[10]; // Assuming "isDisabled" is at the 10th position

          let tierLabel = "N/A";
          if (isDisabled) {
            tierLabel = "Desactivado";
          } else {
            if (!isActive && !isGoldPlan) {
              tierLabel = "Nivel Básico";
            } else if (isActive && !isGoldPlan) {
              tierLabel = "Nivel Intermedio";
            } else if (isActive && isGoldPlan) {
              tierLabel = "Nivel Avanzado";
            }
          }

          // Add conditional styling for disabled users
          const cellStyle = isDisabled ? { color: "red" } : {};

          return (
            <span style={cellStyle}>
              {isDisabled ? "Deshabilitado" : tierLabel}
            </span>
          );
        },
      },
    },
    {
      name: "isAdmin",
      label: "Tipo de Usuario",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta.rowData;
          const isDisabled = rowData[10]; // Assuming "isDisabled" is at the 10th position

          if (isDisabled) {
            return "Deshabilitado";
          } else {
            return value ? "Administrador" : "Regular";
          }
        },
      },
      filter: true,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString(); // Format the date as desired

            return formattedDate;
          } else {
            return "sin fecha";
          }
        },
        filter: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = usersFromDb[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
        filter: false,
      },
    },
    {
      name: "delete",
      label: "Estado",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = usersFromDb[dataIndex];
          const fullName = `${data?.name} ${data?.lastname}`;
          const id = data.id;
          const isDisabled = data.isDisabled;

          const handleActivate = () => {
            Swal.fire({
              title: "Activar Usuario",
              text: `¿Está seguro que desea activar a ${data.name} ${data.lastname} con correo ${data.email}?`,
              confirmButtonText: "Activar",
              showDenyButton: true,
              denyButtonText: "Cancelar",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const body = {
                  uid: data.id,
                  disabled: false,
                };
                const loadingSwal = MySwal.fire({
                  title: "Guardando...",

                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                try {
                  if (isValidToken(idToken)) {
                    const res = await deleteUser(body, idToken);
                    if (res.ok) {
                      // await activateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario activado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al activar el usuario", "", "error");
                    }
                  } else {
                    const newToken = await refreshToken();

                    const res = await deleteUser(body, newToken);
                    if (res.ok) {
                      // await activateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario activado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al activar el usuario", "", "error");
                    }
                  }
                } catch (error) {
                  console.error("Error deleting user:", error);
                  loadingSwal.close();
                  MySwal.fire(
                    "Hubo un error al activar el usuario",
                    "",
                    "error"
                  );
                }
              }
            });
          };

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: `¿Está seguro que desea eliminar a ${fullName} con correo ${data.email}?`,
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const body = {
                  uid: data.id,
                  disabled: true,
                };
                const loadingSwal = MySwal.fire({
                  title: "Guardando...",

                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                try {
                  if (isValidToken(idToken)) {
                    const res = await deleteUser(body, idToken);
                    if (res.ok) {
                      // await deactivateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario eliminado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al eliminar el usuario", "", "error");
                    }
                  } else {
                    const newToken = await refreshToken();

                    const res = await deleteUser(body, newToken);
                    if (res.ok) {
                      // await deactivateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario eliminado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al eliminar el usuario", "", "error");
                    }
                  }
                } catch (error) {
                  console.error("Error deleting user:", error);
                  loadingSwal.close();
                  MySwal.fire(
                    "Hubo un error al eliminar el usuario",
                    "",
                    "error"
                  );
                }
              }
            });
          };

          if (isDisabled) {
            return (
              <IconButton
                sx={{ backgroundColor: theme.palette.primary.main }}
                onClick={() => handleActivate(data.id)}
                disabled={!isSuperAdmin && !isAlmighty}
              >
                <MdAdd />
              </IconButton>
            );
          }
          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },

        filter: false,
      },
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };

  const handleTableChange = (action, tableState) => {
    if (action === "changePage" && !isSearching) {
      // Check if the action is a page change
      const { page, rowsPerPage } = tableState;
      const nextPage = page + 1; // Calculate the next page
      const totalPages = Math.ceil(usersFromDb.length / rowsPerPage); // Calculate total pages based on fetched users
      if (nextPage > totalPages) {
        setcount(count + 15);
        // Check if the next page exceeds the total pages
        dispatch(fetchMoreUsers(rowsPerPage)); // Fetch more users
      }
    }
    if (action === "changeRowsPerPage") {
      // Update the limit when the rows per page changes
      setLimit(tableState.rowsPerPage);
    }
  };
  const handlePageChange = () => {};
  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 15,

    count: count,

    onTableChange: handleTableChange, // Call handleTableChange when the table changes
    search: false,

    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      // console.log(values.forEach((val) => console.log(val)));

      const json = values.map((val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].name] = v;
        });
        return temp;
      });

      const fileName = "Users"; // Declare fileName here
      const ws = XLSX.utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default CSV download from the table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
        downloadCsv: "Download XLSX",
      },
    },
    // onTableChange: () => setUsersFromDB(users),

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          totalCount={totalCount}
        />
      );
    },

    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  if (users === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl">
        <div className="divider"></div>
        <Box sx={{ mt: 6 }}>
          <AddUsers refresh={handleRefresh} isAdmin={isAdmin} />
        </Box>
        <Box>
          <CustomSearch
            searchType={searchType}
            setSearchType={setSearchType}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            hideSearch={hideSearch}
          />
        </Box>
        <div className="table-wrapper   mt-4">
          <MUIDataTable
            data={usersFromDb}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </div>

        <button onClick={handleLoadMore}></button>
        {selectedItem && (
          <UsersEditModal
            item={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={handleRefresh}
          />
        )}
      </Container>
    </div>
  );
}
